import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addMessage, deleteMessage, getMessages } from '../../actions/message.action'
import './Message.scss'

export const Message = (props) => {
    const messageData = props.messages;
    const dispatch = useDispatch()

    return (
        <>
            <div className="Message border rounded">{ 
                messageData && messageData.map((m, key) => {
                    return <div key={key} className={"row mb-2 rounded shadow alert-" + m.type}>
                        {/* <div className="col-2 fw-bold bg-danger text-light d-flex justify-content-center align-items-center">
                            <div onClick={() => { dispatch(deleteMessage(m.message, m.type)) }} style={{ cursor: 'pointer' }} className='mt-2'>X</div>
                        </div> */}
                        <div onClick={() => { dispatch(deleteMessage(m.message, m.type)) }} className="col-10 rounded-4 text-center p-2">
                        <div className="Message__close fs-5">x</div>
                            {m.message}
                            </div>
                    </div>

                })
            }
            </div>
        </>

    );
}