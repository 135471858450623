import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getToken, getUser } from '../../actions/user.action';
import { SideBar } from '../Navbar/SideBar';

export const Dashbaord = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state)=>state.userReducer);
  const token = window.localStorage.getItem("token");

  useEffect(() => {
    dispatch(getUser(token));
    dispatch(getToken(token));
    //console.log(token)
    
  }, [token])
  


  return (
    <div>
      <SideBar/> 
    </div>
  )
}
