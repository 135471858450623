import axios from 'axios';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import { getOrders } from '../../actions/order.action';
import { getProducts } from '../../actions/product.action';
import { getToken, getUser } from '../../actions/user.action';
import './SignIn.css';

export const SignIn = (props) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        setError([]);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}token`).then((response) => {
            //console.log(response.data);
            axios({
                url: `${process.env.REACT_APP_API_BASE_URL}login`,
                method: 'post',
                data: {
                    _token: response.data,
                    email: email,
                    password: password,
                    device_name: "P5"
                },
                headers: {
                    "X-CSRF-TOKEN": response.data,
                }
            })
                .then((res) => {
                    const userToken = res.data;
                    dispatch(getUser(userToken));
                    dispatch(getToken(userToken));
                    dispatch(getProducts(userToken))
                    dispatch(getOrders(userToken));
                    window.localStorage.setItem("token", userToken);
                    navigate("/");
                    setError([]);

                }
                )
                .catch((err) => { console.log(err); setError([err.data]); setLoading(false) })
        }).catch((err) => { console.log(err); setLoading(false); setError([err.data]) })

    }

    return (
        <div className='SignIn border' style={{ overflow: 'hidden', height: "100vh", width: '100vw' }}>
            <div style={{ overflow: 'hidden', height: "100vh", width: '100vw' }} className="row justify-content-center p-3">

                <form action="*" className='col-11 col-sm-8 col-md-6 bg-light col-lg-4 border rounded-3 p-4 shadow'>
                    <h3 classname="fw-bold">Administration KFA</h3>
                    <img style={{height:'150px'}} src="./assets/undraw_engineering_team_re_fvat.svg" alt="" className="img-fluid" />
                    {error.length !== 0 &&
                        <div className="alert alert-danger">Mot de passe ou identifiant incorrect</div>
                    }

                    <div class="form-group p-2">
                        <label for="email">Email</label>
                        <input onChange={(e) => setEmail(e.target.value)} value={email} required type="text" name="email" id="email" class="form-control" placeholder="example@gmail.com" />
                    </div>
                    <div class="form-group p-2">
                        <label for="password">Mot de passe</label>
                        <input onChange={(e) => setPassword(e.target.value)} value={password} required type="password" name="password" id="password" class="form-control" placeholder="votre mot de passe" />
                    </div>
                    <br />
                    <button disabled={loading} onClick={(e) => handleSubmit(e)} type="submit" style={{height:'45px'}} class="btn btn-primary w-100 text-uppercase fw-bold">{!loading?"Connexion":"Connexion..."}</button>

                </form>

            </div>

        </div>
    )
}
