import { TRUE, FALSE } from "../actions/loader.action";

const initialState = true

export default function loaderReducer(state=initialState, action){
    switch (action.type) {
        case TRUE:
            return true
        case FALSE:
            return false
        default:
            return state;
    }
}