import React from 'react'
import './CardAnalytic.scss'

export const CardAnalytic = (props) => {
    const className = props.className;
    const {up, percent, period} = props.comparator;

    return (
        <div style={props.style} className={"CardAnalytic text-light " + className}>
            <div className="row p-2 py-4">
                <div className="col-12 fs-1 d-flex ">{props.value} {props.unity ?? ""}</div>
                <hr/>
                <div className="col-12 d-flex">{props.name}</div>
                {false && <div className="col-12">Chart</div>}
                {false && <div className={up?"col-12 d-flex bg-white text-success":"col-12 d-flex bg-white text-danger"}>
                    <div className={up?"bi bi-arrow-up":"bi bi-arrow-down"}></div> <span className="fw-bold">{percent}%</span>&nbsp;par rapport {period}
                </div>}
            </div>
        </div>
    )
}
