import axios from "axios";
import { hideLoader, showLoader } from "./loader.action";


export const GET_STOCK = "GET_STOCK";


export const getStock = (token)=>{
    return (dispatch)=>{
        dispatch(showLoader())
         axios({
            url: `${process.env.REACT_APP_API_BASE_URL}stocks`,
            method: 'get',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res)=>{
            dispatch({
                type: GET_STOCK,
                payload: res.data
            })
            dispatch(hideLoader())
        }).catch(err=>{console.log(err.data); dispatch(hideLoader())});
    }
}