import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SideBar } from '../../Navbar/SideBar'
import { addMessage } from '../../../actions/message.action'
import { NewStockCommand } from './Product/NewStockCommand';
import { Dialog } from '../Dialog';
import { deleteStockCommand, getStockCommand, updateStockCommand } from '../../../actions/stockcommand.action';
import { getStock } from '../../../actions/stock.action';
import { format } from 'date-fns'
import { PageInfo } from '../../utils/PageInfo';

export const Stock = () => {
  const stockData = useSelector((state) => state.stockReducer);
  const userData = useSelector((state) => state.userReducer);
  const stockCommandData = useSelector((state) => state.stockCommandReducer);
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("token");
  const [showNewStockCommandModal, setShowNewStockCommandModal] = useState(false)
  const [showStockCommand, setShowStockCommand] = useState(false)
  const [q, setQ] = useState(null)
  const [dialogMessage, setDialogMessage] = useState(null)

  const handleDelete = (stockCommand) => {
    setShowStockCommand(false)
    setDialogMessage("suppression en cours...")
    dispatch(deleteStockCommand(token, stockCommand,
      (message) => {
        dispatch(addMessage(message, "success"))
        dispatch(getStockCommand(token))
        setDialogMessage(null)
      }),
      (message) => {
        dispatch(addMessage(message, "danger"))
        setDialogMessage(null)
      })


  }
  const handleValidate = (stockCommand) => {
    setShowStockCommand(false)
    setDialogMessage("validation en cours...")
    stockCommand = {
      ...stockCommand,
      status: "validated",
    }
    dispatch(updateStockCommand(token,
      stockCommand,
      (message) => {
        dispatch(addMessage(message, "success"))
        dispatch(getStockCommand(token))
        dispatch(getStock(token))
        setDialogMessage(null)
      },
      (message) => {
        dispatch(addMessage(message, "danger"))
        setDialogMessage(null)
      }))
  }






  return (
    <SideBar>
      <PageInfo
        title="Gestion du stock"
        description="Commandez les différents produits en rupture avec des prix personnalisables et insérez les dans votre stock global." />
      {showNewStockCommandModal && <NewStockCommand show={true} title={<><small className='bi bi-save'></small> Ajouter des produits au stock</>} onDismiss={() => setShowNewStockCommandModal(!showNewStockCommandModal)} />}
      {dialogMessage && <Dialog message={dialogMessage} onDismiss={() => { }} />}
      <div className="row m-2" style={{ overflowY: "scroll", maxHeight: "60vh" }}>
        <div className="row d-flex mb-2 mt-2">

          <div className="col-6 col-sm-3">
            <button type="button" name="" id="" class="btn btn-warning"><i className="bi bi-activity"></i> Produits unique : {stockData.length}</button>
          </div>

          <div className="col-5 col-sm-4">
            <input value={q} onChange={(e) => setQ(e.target.value)} placeholder='rechercher un produit' type="text" className="form-control mr-sm-2" />
          </div>
          <div className="col-2 d-none d-sm-block">
            <div class="form-group">
              <select class="custom-select form-control" name="" id="">
                <option selected value={"date"}>Trier par</option>
                <option value={"date"}>Date</option>
                <option value="price">Prix</option>
                <option value="sold">Vente</option>
                {false && <option value="quantity">Quantité</option>}
              </select>
            </div>
          </div>

          <div className="col-1 d-none d-sm-block">
            <div class="form-group">
              <select class="custom-select form-control" name="" id="">
                <option selected>Ordre</option>
                <option value="asc">Croissant</option>
                <option value="desc">Décroissant</option>
              </select>
            </div>
          </div>
          {userData.is_super_admin&&<div className="col-1 col-sm-2">
            <button onClick={() => setShowNewStockCommandModal(!showNewStockCommandModal)} type="button" name="" id="" class="btn btn-primary"><i className="bi bi-plus"></i><div className="d-none d-block">Ajouter</div></button>
          </div>}


        </div>
        {stockCommandData.length > 0 && <div className="row">
          <div className="col-7 col-sm-3">
            <div class="form-check">
              <label class="form-check-label">
                <input checked={showStockCommand} type="checkbox" class="form-check-input" name="" id="" value="checkedValue" onChange={(e) => setShowStockCommand(!showStockCommand)} />
                Afficher les commandes
              </label>
            </div>

          </div>
        </div>}
        {showStockCommand && stockCommandData.length > 0 && <div className="row align-content-center">
          <table class="table table-striped table-inverse table-responsive-sm">
            <thead class="thead-inverse">
              <tr>
                <th>#</th>
                <th>Code</th>
                <th>Date</th>
                <th>Image</th>
                <th>Désignation</th>
                <th>Prix</th>
                <th>Quantité</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>

              {stockCommandData.length > 0 &&
                stockCommandData.map((sc, key) => {
                  //const { order, revenue, quantity } = getProductInfo(p)
                  const output = <tr key={key}>
                    <td>{sc.product.id}</td>
                    <td>{sc.product.code}</td>
                    <td>{format(new Date(sc.updated_at), "dd/MM/yyyy à H:ss")}</td>
                    <td>
                      <img style={{ height: "5vh" }} src={process.env.REACT_APP_IMAGE_BASE_PATH + sc.product.picture} alt="" className='img img-fluid' />
                    </td>
                    <td>{sc.product.name}</td>
                    <td>{sc.price} FCFA</td>
                    <td>{sc.quantity}</td>
                    <td>{sc.quantity * sc.price} FCFA</td>
                    <td>
                      <div className="row">
                        <div onClick={null} className="col-6"><button onClick={(e) => { handleValidate(sc) }} title='Valider la commande' type="button" class="btn btn-success"><span className="bi bi-check"></span></button></div>
                        <div className="col-6"><button onClick={(e) => { handleDelete(sc) }} title='Supprimer la commande' type="button" class="btn btn-danger"><span className="bi bi-trash"></span></button></div>
                      </div>
                    </td>
                  </tr>
                  return output

                })
              }

            </tbody>
          </table>
        </div>}
        {stockData && stockData.length !== 0 &&
          stockData.map((s, key) => {
            if (s.quantity === 5) {
              //dispatch(addMessage("Le produit "+s.product.name+" est en rupture de stock.", "warning"))

            }
            const output = <div key={key}  class="bg-light rounded col-xs-10 col-sm-4 col-md-4 col-lg-2 rounded-1 item border m-1 hover-shadow " style={{ cursor: "pointer" }}>
              <img style={{width:"250px",height:"150px"}} src={process.env.REACT_APP_IMAGE_BASE_PATH +"/public/"+ s.product.picture} alt="" className="img-fluid w-100 mt-2" />
              <div className="card-body border-top w-100">
                <h5 className="card-title">
                  {s.product.name}
                </h5>
                {false && <span className='fw-bold text-black-50'>{s.product.price} XAF</span>}
                <hr />
                <div class={s.quantity < 20 ? "d-flex justify-content-around align-items-center text-white bg-danger rounded" : "d-flex justify-content-around align-items-center text-white bg-primary rounded"} role="alert">
                  <i class="bi-stack"></i> <span>stock : {s.quantity}</span>
                </div>
              </div>
            </div>
            const pName = s.product.name;
            if (q !== null && q.length > 0) {
              if (pName.toLowerCase().includes(q.toLowerCase())) {
                return output
              }
            } else {
              return output
            }

          })
        }
      </div>
    </SideBar >
  )
}
