import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addMessage } from '../../../actions/message.action';
import { getOrders, updateOrder } from '../../../actions/order.action';
import { SideBar } from '../../Navbar/SideBar'
import { Dialog } from '../Dialog';
import { format } from 'date-fns';
import { DateRangePicker } from '../../utils/DateRangePicker';
import { PageInfo } from '../../utils/PageInfo';
import { NewBilling } from './Sale/NewBilling';
import { Billing } from './Sale/Billing';
import { useRef } from 'react';
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { mapOrder, priceFormat } from '../../../utils/utils';
import './Sale.scss';


export const Sale = () => {
  const orderData = useSelector((state) => state.orderReducer);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    period: 'today',
  });

  const [comparatorPeriod, setComparatorPeriod] = useState();
  const [q, setQ] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null)

  const handleOnDateRangeChange = (selection) => {
    setDateRange(selection);

    if (selection.period === "perso") { setComparatorPeriod("à la période précédente") }
    else if (selection.period === "today") { setComparatorPeriod("à Hier") }
    else if (selection.period === "yesterday") { setComparatorPeriod("à Avant-Hier") }
    else if (selection.period === "week") { setComparatorPeriod("à la semaine précédente") }
    else if (selection.period === "month") { setComparatorPeriod("au mois précédent") }
    dispatch(
      getOrders(token,
        format(new Date(selection.startDate), "yyyy-MM-dd"),
        format(new Date(selection.endDate), "yyyy-MM-dd")
      ))
  }
  const dispatch = useDispatch()
  const token = window.localStorage.getItem("token")
  const [toGetBill, setToGetBill] = useState(null)
  const [billConfig, setBillConfig] = useState({
    tva: 0,
    ir: 0,
    address: "",
    ref: "",
    proforma: false,
    delivery: false,
    invoice: false,
    deliveryNumber: 0,
    bc: 0,
    price: "",
    deadline: new Date()
  })

  const handlePayBill = (order) => {
    setDialogMessage("Règlement de la facture en cours...")
    order = {
      id: order.id,
      customer_id: order.customer.id,
      status: "validated"
    }
    //console.log(order)
    dispatch(updateOrder(token, order,
      (message) => {
        dispatch(addMessage(message, "success"))
        dispatch(getOrders(token))
        setDialogMessage(null)
      },
      (message) => {
        dispatch(addMessage(message, "danger"))
        setDialogMessage(null)
      }
    ))

  }

  const handleGenerateBilling = (order) => {
    //console.log(order)
    setToGetBill(order)
    setBillConfig({ ...billConfig, address: `${order.customer.firstname}<br>\n${order.customer.phone}<br>\nBP:00000<br>` })
  }
  const billRef = useRef()

  const handlePrint = () => {
    const content = billRef.current;
    const pdfWidth = 210; // Width of the A4 format in millimeters
    const scaleFactor = pdfWidth / (content.offsetWidth / 5);

    const dpi = 300; // Increase DPI for better resolution

    html2canvas(content, { scale: scaleFactor, dpi: dpi }).then((canvas) => {
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: [210, 297]
      });
      pdf.addImage(canvas.toDataURL('image/png', 1.0), 0, 0, pdfWidth, 0);
      pdf.save(format(new Date(), 'yyyy-mm-dd-hh-ii-ss') + '.pdf');
    });
  };


  const [billTitle, setBillTitle] = useState("Génération de la facture client")

  const handleConfigChange = (config) => {
    setBillConfig(config);
    const _order = mapOrder(toGetBill)
    const _price = priceFormat(_order.price + _order.price * config.tva / 100 - _order.price * config.ir / 100)
    setBillTitle("Génération de la facture client (" + _price + ")")
  }

  return (
    <SideBar>
      {toGetBill && <NewBilling
        options={billConfig}
        title={billTitle}
        onSave={() => { handlePrint() }}
        onDismiss={() => { setToGetBill(null) }}
        order={toGetBill}
        onBillConfigChange={(config) => { handleConfigChange(config) }}
      />}
      {toGetBill && <div style={{ width: "210mm", height: "297mm" }} className="row" ref={billRef}><Billing order={toGetBill} options={billConfig} /></div>}
      <PageInfo
        title="Vue globale des ventes"
        description="D'un coup d'oeil inspectez et réglez les différentes ventes, imprimez ainsi les factures" />

      {dialogMessage && <Dialog message={dialogMessage} onDismiss={() => { }} />}
      <div className="row">
        <div className="col-10">
          {true && <DateRangePicker onChange={handleOnDateRangeChange} />}
        </div>
      </div>
      <div className="row d-flex">
        <div className="col"></div>
        <div className="col col-6">
          <input value={q} onChange={(e) => setQ(e.target.value)} placeholder='rechercher un produit ou un client' type="text" className="form-control mr-sm-2" />
        </div>

        <div className="col col-2">
          <div class="form-group">
            <select class="custom-select form-control" name="" id="">
              <option selected value={"date"}>Trier par</option>
              <option value={"date"}>Date</option>
              <option value="price">Prix</option>
              <option value="sum">Total</option>
              <option value="quantity">Quantité</option>
            </select>
          </div>
        </div>

        <div className="col col-1">
          <div class="form-group">
            <select class="custom-select form-control" name="" id="">
              <option selected>Ordre</option>
              <option value="asc">Croissant</option>
              <option value="desc">Décroissant</option>
            </select>
          </div>
        </div>

      </div>

      <div className="table-container">
        <table class="table table-striped bg-light mt-2 rounded table-inverse table-responsive-sm">
          <thead class="thead-inverse">
            <tr>
              <th>#</th>
              <th>Status</th>
              <th>Date</th>
              <th>Désignation</th>
              <th>Quantité</th>
              <th>Prix</th>
              <th>Client</th>
              <th>Vendeur</th>
              <th>Action</th>
              <th>Facture</th>

            </tr>
          </thead>
          <tbody>
            {orderData.length !== 0 && orderData.map((o, key) => {
              const output = <tr key={key}>
                <td>{o.id}</td>
                <td className={o.status === "pending" ? "text-danger" : "text-success"}>{o.status === "pending" ? "impayé" : "payé"}</td>
                <td>{format(new Date(o.updated_at), "dd/MM/yyyy à H:ss")}</td>
                <td>{o.names}</td>
                <td>{o.products.length}</td>
                <td>{o.price} FCFA</td>
                <td>{o.customer.firstname}{o.customer.phone != null ? (o.customer.phone) : null}</td>
                <td>{o.user.name}</td>
                <td>
                  {o.status === "pending" ? <button onClick={() => handlePayBill(o)} title='Réglez la facture' className='btn btn-outline-warning'><i className="bi bi-cash-stack"></i></button> : ""}

                </td>
                <td>
                  <button onClick={() => handleGenerateBilling(o)} title='Imprimez la facture' className='btn btn-outline-success'><i className="bi bi-printer"></i></button>
                </td>
              </tr>
              const oName = o.names + "," + o.customer.firstname + "," + o.user.name
              if (q !== null && q.length > 0) {
                if (oName.toLowerCase().includes(q.toLowerCase())) {
                  return output
                }
              } else {
                return output
              }

            })}

          </tbody>
        </table>
      </div>
    </SideBar>
  )
}
