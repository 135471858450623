import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { SideBar } from '../../Navbar/SideBar'
import './Commercial.scss'
import { PageInfo } from '../../utils/PageInfo'
import { NewCommercial } from './Commercial/NewCommercial'
import { EditCommercial } from './Commercial/EditCommercial'

export const Commercial = () => {
  const commercialData = useSelector((state) => state.commercialReducer)
  const [showNewCommercialModal, setShowNewCommercialModal] = useState(false)
  const [commercialToEdit, setCommercialToEdit] = useState(null)

  return (
    <SideBar>
      <PageInfo
        title="Gestion des commerciaux"
        description="Consultez et gérez vos différents commerciaux, modifiez leurs identités et leur disponibilité." />
      {showNewCommercialModal && <NewCommercial show={true} title={<><small className='bi bi-save'></small> Enregistrer un nouveau commercial</>} onOpen={() => setShowNewCommercialModal(true)} onDismiss={() => setShowNewCommercialModal(false)} />}
      {commercialToEdit && <EditCommercial {...commercialToEdit} show={true} title={<><small className='bi bi-pencil'></small> Editer le commercial "{commercialToEdit.name}"</>} onDismiss={() => setCommercialToEdit(null)} />}


      <div className="row p-2">
        <div className="col-9"></div>
        <div className="col-3">
          <button onClick={() => setShowNewCommercialModal(true)} title='Ajouter un commercial' type="button" name="" id="" class="d-flex justify-content-around align-items-center btn btn-primary btn-lg btn-block">
            <i className="bi bi-person fs-2"></i>
            <div className="d-none d-sm-flex">
            &nbsp; Nouveau commercial
            </div>
          </button>
        </div>
      </div>
      <div className="row m-1 p-1 gap-3">
        {commercialData && commercialData.length > 0 && commercialData.map((c) => {

          const output = <div className="commercial bg-light rounded col-sm-3">
            <div className="row">
              <div className="col-9 m-2">
                <img src="./../assets/avatar.svg" class="img-fluid" alt="" />
              </div>
              <div className="col-2 rounded-2">
                <div class="btn-group dropend">
                  <button className="action-btn" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                  <ul class="dropdown-menu">
                    <li><button onClick={() => setCommercialToEdit(c)} class="dropdown-item" type="button">Modifier</button></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 m-2"><h4>{c.name}</h4></div>
            </div>
            <div className="row bg-light">
              <div className="col-10 m-3 fw-bold text-muted">{c.status}</div>
            </div>
            {false && <div className="row bg-light">
              <div className="col-10 m-2 small text-muted">
                {format(new Date(c.created_at), "dd/mm/yyyy")}
              </div>
            </div>}
          </div>
          return output
        })}
      </div>
    </SideBar>
  )
}

