import { ADD_PRODUCT, EDIT_PRODUCT, GET_PRODUCTS } from "../actions/product.action";

const initialState = []

export default function productReducer(state=initialState, action){
    switch (action.type) {
        case GET_PRODUCTS:
            return action.payload;
        case ADD_PRODUCT:
            const product = action.payload
            state = [
                ...state,
                product
            ]
            return state
        case EDIT_PRODUCT:
            const p1 = action.payload
            state = state.map((p0)=> {return p0.id===p1.id?p1:p0})
            return state
        default:
            return state;
    }
}