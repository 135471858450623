import React from 'react'
import { SignIn } from '../components/Account/SignIn'
import { Dashbaord } from '../components/Account/Dashbaord';


export const Account = () => {

  const token = window.localStorage.getItem("token");

  //dispatch(getUser(token));
  //dispatch(getToken(token));

  return (
    <div style={{ overflow: 'hidden', maxHeight: "100vh" }}>
      {(token===null || token===undefined) && <SignIn/>}
      {(token!==null && token!==undefined) && <Dashbaord/>}
    </div>
  )
}
