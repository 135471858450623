import axios from "axios";
import { hideLoader, showLoader } from "./loader.action";

export const ADD_STOCKCOMMAND = "ADD_STOCKCOMMAND"
export const GET_STOCK_COMMAND = "GET_STOCK_COMMAND"
export const DELETE_STOCK_COMMAND = "DELETE_STOCK_COMMAND"
export const UPDATE_STOCK_COMMAND = "UPDATE_STOCK_COMMAND"

export const addStockCommand = (token, stockCommand, onSuccess, onError) => {
    return (dispatch) => {
        dispatch(showLoader())
        axios.post(
            `${process.env.REACT_APP_API_BASE_URL}stockcommands`,
            stockCommand, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then((res) => {
            dispatch({
                type: ADD_STOCKCOMMAND,
                payload: res.data
            })
            onSuccess("La commande a bien été enregistré.")
            dispatch(hideLoader())
        }).catch((err) => {
            console.log(err.response.data)
            onError(err.response.data.message)
            dispatch(hideLoader())
        });
    }
}

export const updateStockCommand = (token, stockCommand, onSuccess, onError) => {
    return (dispatch) => {
        dispatch(showLoader())
        axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}stockcommands/${stockCommand.id}`,
            stockCommand, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then((res) => {
            dispatch({
                type: UPDATE_STOCK_COMMAND,
                payload: res.data
            })
            console.log(res.data)
            onSuccess("La commande a bien été modifié.")
            dispatch(hideLoader())
        }).catch((err) => {
            console.log(err.response.data)
            onError(err.response.data.message)
            dispatch(hideLoader())
        });
    }
}

export const getStockCommand = (token) => {
    return (dispatch) => {
        dispatch(showLoader())
        axios({
            url: `${process.env.REACT_APP_API_BASE_URL}stockcommands`,
            method: 'get',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            dispatch({
                type: GET_STOCK_COMMAND,
                payload: res.data
            })
            dispatch(hideLoader())
        }).catch(err => {console.log(err.data); dispatch(hideLoader())});
    }
}

export const deleteStockCommand = (token, stockCommand, onSuccess, onError) => {
    return (dispatch) => {
        dispatch(showLoader())
        axios({
            url: `${process.env.REACT_APP_API_BASE_URL}stockcommands/${stockCommand.id}`,
            method: `delete`,
            stockCommand,
            headers: {
                Authorization: `Bearer ${token}`
            }

        }).then((res) => {
            dispatch({
                type: DELETE_STOCK_COMMAND,
                payload: res.data
            })
            onSuccess("Suppression de la commande réussie.")
            dispatch(hideLoader())
        }).catch(err => {
            console.log(err.data);
            onError("Impossible de supprimer la commande.")
            dispatch(hideLoader())
        });
    }
}