import axios from "axios";

export const GET_USER = "GET_USER";
export const GET_TOKEN = "GET_TOKEN";

export const getUser = (token)=>{
    return (dispatch)=>{
        axios({
            url: `${process.env.REACT_APP_API_BASE_URL}user`,
            method: 'get',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(
            (res)=>dispatch({
                type: GET_USER,
                payload: res.data
            })
        ).catch(err=>console.log(err));
    }
}

export const getToken = (token)=>{
    return (dispacth)=>{
        dispacth({
            type: GET_TOKEN,
            payload: token
        })
    }
}