import {
    ADD_STOCKCOMMAND,
    DELETE_STOCK_COMMAND,
    GET_STOCK_COMMAND,
    UPDATE_STOCK_COMMAND
} from "../actions/stockcommand.action";

const initialState = {}

export default function stockCommandReducer(state = initialState, action) {
    const stockCommand = action.payload
    switch (action.type) {
        case GET_STOCK_COMMAND:
            return action.payload;
        case ADD_STOCKCOMMAND:
            
            state = [
                ...state,
                stockCommand
            ]
            return state
        case UPDATE_STOCK_COMMAND:
            return state;
        case DELETE_STOCK_COMMAND:
            state = state.filter((value, index)=>{
                return value.id !== stockCommand.id
            })
            return state
        default:
            return state;
    }
}