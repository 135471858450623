

export const priceFormat = (price) => {
    return (
        new Intl.NumberFormat('cm-CM', { style: 'currency', currency: 'XAF' })
    ).format(price)
}

export const mapOrder = (order) => {
    let output = []
    let ids = []
    order.products.map((p, k) => {
        if (ids.includes(p.id)) {
            output = output.map((pp, kk) => {
                if (pp.id === p.id) {
                    return { ...pp, quantity: pp.quantity + 1 }
                } else {
                    return pp
                }
            })
        } else {
            ids.push(p.id)
            output.push({ ...p, quantity: 1 })
        }
    })
    return { ...order, products: output }
}