import { ADD_COMMERCIAL, EDIT_COMMERCIAL, GET_COMMERCIALS } from "../actions/commercial.action";


const initialState = {}

export default function commercialReducer(state=initialState, action){
    switch (action.type) {
        case GET_COMMERCIALS:
            return action.payload;
        case ADD_COMMERCIAL:
            const user = action.payload
            state = [
                ...state,
                user
            ]
            return state
        case EDIT_COMMERCIAL:
            const p1 = action.payload
            state = state.map((p0)=> {return p0.id===p1.id?p1:p0})
            return state
        default:
            return state;
    }
}