export const TRUE = "TRUE";
export const FALSE = "FALSE";

export const showLoader = () => {
    return (dispatch) => {
        dispatch({
            type: TRUE,
            payload: true
        })
    }
}

export const hideLoader = () => {
    return (dispatch) => {
        dispatch({
            type: FALSE,
            payload: false
        })
    }
}