import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { getMessages } from '../../actions/message.action';
import { Loader } from '../utils/Loader';
import { Message } from '../utils/Message';
import { DashboardHeader } from './DashboardHeader'
import './SideBar.scss'
import { getUser } from '../../actions/user.action';

export const SideBar = (props) => {

    const userData = useSelector((state) => state.userReducer);
    const dispatch = useDispatch()
    const messageData = useSelector((state) => state.messageReducer)
    const loadingData = useSelector((state) => state.loaderReducer)

    const sideBarRef = useRef()
    const toggleButtonRef = useRef()
    const safeAreaRef = useRef()

    const token = window.localStorage.getItem('token');

    const navigate = useNavigate();


    useEffect(() => {
        if(token !== null){
            dispatch(getUser(token))
        }else{
            navigate('/account')
        }
        dispatch(getMessages())

    }, [messageData]);

    useEffect(() => {
        toggleButtonRef.current.addEventListener('click', (e)=>{
            if(sideBarRef.current.classList.contains('open')){
                sideBarRef.current.classList.remove('open');
                safeAreaRef.current.style.width = '100vw';
                safeAreaRef.current.style.marginLeft = '0px';
            }else{
                sideBarRef.current.classList.add('open');
                safeAreaRef.current.style.width = 'calc(100vw - var(--sideBarWidth))';
                safeAreaRef.current.style.marginLeft = 'var(--sideBarWidth)';
            }
        })
    }, [])

    return (
        <>
            {loadingData && <Loader />}
            <div class="container-fluid sidebar-container" >
                <div className="row d-flex fixed-top position-fixed" style={{ height: "72px" }}>
                    <DashboardHeader ref={toggleButtonRef} />
                </div>
                <div class="row flex-nowrap position-xs-absolute" style={{width:'100vw', marginTop: "71px" }}>
                    <div ref={sideBarRef} className={"sidebar open shadow"}>
                        <div style={{ marginLeft: '12px' }} className="d-flex flex-column justify-content-start pt-2 text-white min-vh-100">

                            <ul class="nav nav-pills flex-column mb-sm-auto  mb-0 p-0 m-0 align-items-start align-items-sm-start" id="menu">

                                <li class="nav-item  w-100 p-0 m-0">
                                    <NavLink exact to="/home" className="nav-link align-middle scrollto w-100">
                                        <i class="fs-4 bi-house"></i> <span>Accueil</span>
                                    </NavLink>
                                </li>
                                <li class="nav-item">
                                    <NavLink exact to="/products" className="nav-link align-middle  scrollto">
                                        <i class="fs-4 bi-grid"></i> <span>Produits</span>
                                    </NavLink>
                                </li>
                                <li class="nav-item">
                                    <NavLink exact to="/shop" className="nav-link align-middle  scrollto">
                                        <i class="fs-4 bi-bootstrap"></i> <span>Boutique</span>
                                    </NavLink>
                                </li>

                                <li class="nav-item">
                                    <NavLink exact to="/sales" className="nav-link align-middle  scrollto">
                                        <i class="fs-4 bi-cash-coin"></i> <span>Vente</span>
                                    </NavLink>
                                </li>
                                <li class="nav-item">
                                    <NavLink exact to="/stock" className="nav-link align-middle  scrollto">
                                        <i class="fs-4 bi-stack"></i> <span>Stock</span>
                                    </NavLink>
                                </li>
                                <li class="nav-item">
                                    <NavLink exact to="/accounting" className="nav-link align-middle  scrollto">
                                        <i class="fs-4 bi-wallet"></i> <span class="ms-1 p-0 d-none d-sm-inline">Comptabilité</span>
                                    </NavLink>
                                </li>
                                {userData.is_super_admin && <li class="nav-item">
                                    <NavLink exact to="/commercial" className="nav-link align-middle">
                                        <i class="fs-4 bi-people"></i> <span class="ms-1 p-0 d-none d-sm-inline">Commerciaux</span>
                                    </NavLink>
                                </li>}
                            </ul>
                            <hr />
                        </div>
                    </div>
                    <div ref={safeAreaRef} className="safeArea" >
                        {props.children}
                    </div>
                </div>
            </div>
            <Message messages={messageData} />
        </>
    )
}
