import { useEffect, React, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCustomer } from '../../../actions/customer.action'
import { addMessage, getMessages } from '../../../actions/message.action'
import { addOrder, getOrders } from '../../../actions/order.action'
import { getProducts } from "../../../actions/product.action"
import { SideBar } from '../../Navbar/SideBar'
import { PageInfo } from '../../utils/PageInfo'
import { Dialog } from '../Dialog'

export const Shop = () => {
  const productData = useSelector((state) => state.productReducer);
  const orderData = useSelector((state) => state.orderReducer);
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("token");

  const [cart, setCart] = useState([]);
  const [cartRealPrices, setCartRealPrices] = useState(() => {
    const output = []
    productData.map((val, index) => {
      output.push([val.id, val.price])
    })
    return output
  })
  const [q, setQ] = useState(null)
  const [dialogMessage, setDialogMessage] = useState(null)
  const [customer, setCustomer] = useState({
    name: "",
    phone: ""
  })

  const handleAddToCart = (product) => {
    const copyCart = cart.slice();
    let newCart = [];

    if (intoCart(product, copyCart)) {
      for (const p of copyCart) {
        if (product.id === p.id) {
          newCart.push(
            {
              ...p,
              quantity: p.quantity + 1
            }
          )
        } else {
          newCart.push(p)
        }
      }
    } else {
      newCart = copyCart.slice()
      newCart.push({
        ...product,
        quantity: 1
      })
    }
    setCart(newCart);
    //console.log(newCart);

  }

  const intoCart = (product, cart) => {
    let into = false;

    for (const p of cart) {
      if (product.id === p.id) {
        into = true
      }
    }
    return into;
  }

  const handleRemoveFromCart = (product) => {
    const copyCart = cart.slice()
    let newCart = []
    for (const p of copyCart) {
      if (product.id !== p.id) {
        newCart.push(p)
      }
    }
    setCart(newCart)

  }

  const handleDecrementFromCart = (product) => {
    const copyCart = cart.slice()
    let newCart = []
    for (const p of copyCart) {
      if (product.id === p.id) {
        if (p.quantity > 1) {
          newCart.push(
            {
              ...p,
              quantity: p.quantity - 1
            }
          )
        } else {
          handleRemoveFromCart(product)
        }

      } else {
        newCart.push(p)
      }
    }
    setCart(newCart)
  }

  const getCartPrice = () => {
    let price = 0;
    const copyCart = cart.slice()
    for (const p of copyCart) {
      price += p.price * p.quantity
    }
    return price

  }

  const handleSale = async () => {
    setDialogMessage("Opération en cours...")
    const { name, phone } = customer
    if (name.length < 3) {
      setDialogMessage(null)
      dispatch(addMessage("Veuillez renseigner le nom du client", "warning"))
      dispatch(getMessages())
      setDialogMessage(null)

      return
    }
    dispatch(addCustomer(token, customer,
      (message, customer_id) => {
        // customer was added
        // We proceed to sold
        const products = []
        cart.forEach(p => {
          for (let i = 0; i < p.quantity; i++) {
            products.push(
              [
                p.id,
                getCartRealPrice(p.id)
              ]
            )
          }

        });
        //console.log(products)

        dispatch(addOrder(token, { products: JSON.stringify(products), customer_id },
          (message) => {
            dispatch(addMessage(message, "success"))
            dispatch(getOrders(token))
            setDialogMessage(null)
            setCart([])
            setDialogMessage(null)
          },
          (message) => {
            dispatch(addMessage(message, "danger"))
            setDialogMessage(null)
          }
        ))
      },
      (message) => {
        dispatch(addMessage(message, "danger"))
        setDialogMessage(null)

      }
    ))

  }


  useEffect(() => {
    try {
      if (productData.length === 0) {
        dispatch(getProducts(token));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(getOrders(token, new Date('2020-01-01'), new Date('3020-01-01')))

  }, [])

  const handleSetCartRealPrices = (id, value) => {
    setCartRealPrices(cartRealPrices.map((e) => {
      if (id === e[0]) {
        return [id, value]
      } else {
        return e
      }
    }))
  }

  const getCartRealPrice = (id) => {
    let price = null
    cartRealPrices.map((e) => {
      if (id === e[0]) {
        price = e[1]
      }
    })
    return price
  }

  return (
    <SideBar>
      <PageInfo
        title="Boutique"
        description="Ajoutez des produits dans le panier, ajustez les prix et vendez en renseignat les coordonées du client." />

      {dialogMessage && <Dialog message={dialogMessage} onDismiss={() => { }} />}
      {cart.length !== 0 && <><div className="shadow row flex-row m-3 border-3 rounded" style={{ overflowY: "scroll", maxHeight: "30vh" }}>
        <div className="row bg-primary text-light p-1 mr-4  ">
          <div className="col-2 col-sm-1 d-none d-sm-block">
            Produit
          </div>
          <div className="col-2 col-sm-2">
            Désignation
          </div>
          <div className="col col-sm-1"></div>
          <div className="col-2 col-sm-1">Quantité</div>
          <div className="col col-sm-1"></div>
          <div className="col-2 col-sm-2">Prix</div>
          <div className="col-2 col-sm-2">Total</div>
          <div className="col col-sm-2"></div>
        </div>

        {cart.map((p, key) => {
          return <div className="row mt-2 p-1" key={key}>
            <div className="col-1 col-sm-1 d-none d-sm-block">
              <img src={process.env.REACT_APP_IMAGE_BASE_PATH + p.picture} class="img-fluid" alt="" />
            </div>
            <div className="col-2 col-sm-2">
              {p.name}
            </div>
            <div className="col-1"><button onClick={(e) => handleAddToCart(p)} type="button" class="btn bg-dark text-light">+</button></div>
            <div className="col-2 col-sm-1"><input className="form-control " style={{ textAlign: "center" }} readOnly type="text" min={1} value={p.quantity} /></div>
            <div className="col-1"><button onClick={(e) => handleDecrementFromCart(p)} type="button" class="btn bg-dark text-light">-</button></div>
            <div className="col-3 col-sm-2 fw-bold">
              <input onChange={(e) => { handleSetCartRealPrices(p.id, e.target.value) }} value={getCartRealPrice(p.id)} type="number"
                class="form-control" name="" id="" aria-describedby="helpId" placeholder={p.price} />
            </div>
            <div className="col-2 col-sm-2 fw-bold">{getCartRealPrice(p.id) * p.quantity} FCFA</div>
            <div className="col-1 col-sm-2 fw-bold"><div onClick={(e) => handleRemoveFromCart(p)} title={"Retirer " + p.name + " du panier"} style={{ width: "25px", height: "25px", cursor: "pointer" }} className='rounded-circle align-content-center fw-bold bg-danger text-light'>x</div></div>
          </div>
        })
        }

      </div>
        <div className="row d-flex shadow bg-warning p-2">
          <div className="col-4 col-sm-3 mt-1">Total: <span className='fw-bold'>{getCartPrice()} FCFA</span></div>
          <div className="col-4 col-sm-3">
            <input list="client-list" name='customer_name' value={customer.name} onChange={(e) => setCustomer({ name: e.target.value, phone: customer.phone })} class="form-control" placeholder="Nom du client" />
            <datalist id="client-list">
              {orderData && orderData.map(({ customer }) => {
                return <option value={customer.firstname} />
              })}
            </datalist>
          </div>
          <div className="col-4 col-sm-3">
            <input list="phone-list" name='customer_phone' value={customer.phone} onChange={(e) => setCustomer({ phone: e.target.value, name: customer.name })} type="text" class="form-control" placeholder="Numéro de téléphone du client" />
            <datalist id="phone-list">
              {orderData && orderData.map(({ customer }) => {
                return <option value={customer.phone} />
              })}
            </datalist>
          </div>
          <div className="col-12 mt-1 col-sm-3"><button onClick={() => handleSale()} type="button" disabled={customer.name.length < 3} class="btn btn-success w-50"><i className="bi bi-cash-coin mt-1"></i> Vendre</button></div>
        </div></>}
      <div className="row">
        <div className="col-11 col-sm-4 m-2">
          <input value={q} onChange={(e) => setQ(e.target.value)} placeholder='rechercher un produit' type="text" className="form-control mr-sm-2" />
        </div>
      </div>

      <div className="row m-2" style={{ overflowY: "scroll", maxHeight: "60vh" }}>
        {productData.length !== 0 &&
          productData.map((p) => {
            const output = <div title={"Ajouter " + p.name + " à la liste"} class="col-xs-10 bg-light rounded col-sm-4 col-md-4 col-lg-2 rounded-1 item border m-1 hover-shadow " style={{ cursor: "pointer" }}>
              <img style={{ width: "250px", height: "150px" }} src={process.env.REACT_APP_IMAGE_BASE_PATH + p.picture} alt="" className="img-fluid w-100 mt-2" />
              <div className="card-body border-top">
                <h5 className="card-title">
                  {p.name}
                </h5>
                <span className='fw-bold text-black-50'>{p.price} XAF</span>
                <a href="#" className="btn w-100 btn-primary" onClick={(e) => handleAddToCart(p)}><span className="bi bi-cart-plus"></span> Ajouter</a>
              </div>
            </div>
            const pName = p.name;
            if (q !== null && q.length > 0) {
              if (pName.toLowerCase().includes(q.toLowerCase())) {
                return output
              }
            } else {
              return output
            }
          })
        }
      </div>
    </SideBar>
  )
}
