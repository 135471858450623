import React, { useState, useRef, useEffect } from 'react'
import { Modal } from '../../../utils/Modal'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../../actions/message.action'
import { editCommercial, getCommercials } from '../../../../actions/commercial.action'

export const EditCommercial = (props) => {
    const formRef = useRef(null)
    const [name, setName] = useState(props.name)
    const [mail, setMail] = useState(props.email)
    const [password, setPassword] = useState(props.password)
    const [status, setStatus] = useState(props.status)
    const token = window.localStorage.getItem("token")
    const [error, setError] = useState(false)

    const dispatch = useDispatch()

    const handleSave = async () => {
        setError(false)
        const data = new FormData(formRef.current);
        let commercial = {
            id: props.id,
            name: data.get("name"),
            mail: data.get("mail"),
            status: data.get("status")
        }
        if (data.get("password")) {
            commercial = {
                ...commercial,
                password: data.get("password"),
            }
        }

        //console.log(commercial)
        try {
            dispatch(editCommercial(token,
                commercial,
                (message) => {
                    dispatch(getCommercials(token))
                    dispatch(addMessage(message, "success")); props.onDismiss();
                },
                (message) => {
                    //dispatch(addMessage(message, "danger"))
                    setError(message)
                }),

            )

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

    }, [])



    return (
        <Modal title={props.title} onSave={() => { handleSave() }} onDismiss={(e) => { props.onDismiss() }} saveTitle={<><small className='bi bi-save'></small> ENREGISTRER</>}>
            <form method='post' ref={formRef} enctype="multipart/form-data" className="row mb-1">
                {error && <div className="alert alert-danger">
                    {error}
                </div>}

                <div className="row mb-1 gap-2">
                    <div className="col-10">
                        <input required name='name' type="text" class="form-control" placeholder='nom du commercial' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="col-10">
                        <input required name='mail' type="mail" class="form-control" placeholder='email du commercial' value={mail} onChange={(e) => setMail(e.target.value)} />
                    </div>
                    <div className="col-10">
                        <input required name='password' type="password" class="form-control" placeholder='mot de passe du commercial' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    {!props.is_super_admin && <div class="col-10">
                        <select name="status" class="custom-select form-control" id="">
                            <option selected value={props.status}></option>
                            <option selected value={"available"}>En service</option>
                            <option value={"locked"}>Hors service</option>
                            <option value={"deleted"}>Banni</option>
                        </select>
                    </div>}
                </div>
            </form>

        </Modal>
    )
}
