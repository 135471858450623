import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'
import { getProducts } from '../../../actions/product.action'
import { SideBar } from '../../Navbar/SideBar'
import { CardViewer } from '../../utils/CardViewer'
import { DateRangePicker } from '../../utils/DateRangePicker'
import { format } from 'date-fns'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { NewProduct } from './Product/NewProduct'
import { EditProduct } from './Product/EditProduct'
import { getOrders } from '../../../actions/order.action'
import { PageInfo } from '../../utils/PageInfo'


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const getProductsMaxByProfit = (products) => {
    let productsMax = products[0];
    for (let product of products) {
        if (productsMax.profit < product.profit) {
            productsMax = product
        }
    }
    return productsMax
}

export const sortProductsByProfit = (products) => {
    const output = []
    const ids = []
    for (let product of products) {
        const pMax = getProductsMaxByProfit(products)
        output.push(pMax)
        ids.push(pMax.id)
        products = products.filter(p => !ids.includes(p.id))
    }
    return output
}



export const Product = () => {
    const dispatch = useDispatch();
    const productData = useSelector((state) => state.productReducer)
    const userData = useSelector((state) => state.userReducer)
    const orderData = useSelector((state) => state.orderReducer)
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        period: 'today',
    });
    const token = window.localStorage.getItem("token")
    const [topProducts, setTopProducts] = useState([])

    const [labels, setLabels] = useState(() => {
        const tab = []
        for (let i = 0; i < 25; i++) {
            const element = i > 9 ? i + "h" : "0" + i + "h";
            tab.push(element)
        }
        return tab
    })
    const data = {
        labels,
        datasets: [
            {
                label: 'FCFA',
                data: labels.map(() => Math.random() * 500),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Ventes',
                data: labels.map(() => Math.random() * 1000),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
    };

    const [showNewProductModal, setShowNewProductModal] = useState(false);


    useEffect(() => {
        const p = sortProductsByProfit(productData.slice())
        const maxSorted = p.length > 4 ? 4 : p.length
        const copyP = [];
        for (let index = 0; index < maxSorted; index++) {
            const element = p[index];

            if (element.order > 0) {
                copyP.push(element)
            }

        }
        setTopProducts(copyP)

    }, [orderData, productData])

    const handleOnDateRangeChange = (selection) => {
        setDateRange(selection);
        switch (selection.period) {
            case 'today':
                setLabels(() => {
                    const tab = []
                    for (let i = 0; i < 25; i++) {
                        const element = i > 9 ? i + "h" : "0" + i + "h";
                        tab.push(element)
                    }
                    return tab
                })
                break;
            case 'yesterday':
                setLabels(() => {
                    const tab = []
                    for (let i = 0; i < 25; i++) {
                        const element = i > 9 ? i + "h" : "0" + i + "h";
                        tab.push(element)
                    }
                    return tab
                })
                break;
            case 'week':
                setLabels(() => {
                    const tab = []
                    for (let i = 0; i < 7; i++) {
                        const date = (new Date())
                        date.setMonth(date.getMonth() + 1)
                        date.setDate(date.getDate() - i)
                        const month = date.getMonth() < 9 ? "0" + date.getMonth() : date.getMonth()
                        const day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate()
                        const element = day + "/" + month
                        tab.push(element)
                    }
                    tab.reverse()
                    return tab
                })
                break;
            case 'month':
                setLabels(() => {
                    const tab = []
                    for (let i = 0; i < 30; i++) {
                        const date = (new Date())
                        date.setMonth(date.getMonth() + 1)
                        date.setDate(date.getDate() - i)
                        const month = date.getMonth() < 9 ? "0" + date.getMonth() : date.getMonth()
                        const day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate()
                        const element = day + "/" + month
                        tab.push(element)
                    }
                    tab.reverse()
                    return tab
                })
                break;
            case 'perso':
                setLabels(() => {
                    const tab = []
                    let { startDate, endDate } = selection

                    for (let i = 0; i < 30; i++) {
                        const date = (new Date())
                        date.setMonth(date.getMonth() + 1)
                        date.setDate(date.getDate() - i)
                        const month = date.getMonth() < 9 ? "0" + date.getMonth() : date.getMonth()
                        const day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate()
                        const element = day + "/" + month
                        tab.push(element)
                    }
                    tab.reverse()
                    return tab
                })
                break;
            default:
                break;
        }
        dispatch(getProducts(token, selection.startDate, selection.endDate))
        dispatch(
            getOrders(token,
                format(selection.startDate, "yyyy-MM-dd"),
                format(selection.endDate, "yyyy-MM-dd")
            ))

    }




    const [productToEdit, setProductToEdit] = useState(null)
    const [q, setQ] = useState(null)
    const [sortBy, setSortBy] = useState("date")
    const [order, setOrder] = useState("asc")


    const filterProduct = (productsData, sortBy, order) => {
        //const products = productData.slice()
        return productData;
        /**const output = []
        productData.sort((a, b) => {

        })**/
    }

    return (
        <SideBar>
            <PageInfo
                title="Vue d'ensemble des produits"
                description="Ajoutez et editez des produits , Bénéficiez de rapports détaillés sur les ventes et l'apport de vos produits sur des périodes personnalisables." />
            {showNewProductModal && <NewProduct show={true} title={<><small className='bi bi-save'></small> </>} onOpen={() => setShowNewProductModal(true)} onDismiss={() => setShowNewProductModal(false)} />}
            {productToEdit && <EditProduct {...productToEdit} show={true} title={<><small className='bi bi-pencil'></small> Editer le produit "{productToEdit.name}"</>} onDismiss={() => setProductToEdit(null)} />}

            <div className="row mt-2">
                <DateRangePicker onChange={handleOnDateRangeChange} />
            </div>


            <div className="row m-3 gap-3">
                <div className="col-12 col-sm-5">
                    {topProducts.length !== 0 && <CardViewer title="Meilleurs produits" style={{ backgroundColor: '#333333' }}>
                        <div className="row">
                            {topProducts.map((p, key) => {
                                return <div key={key} className="row m-1 gap-2">
                                    <div className="col-2">
                                        <img src={process.env.REACT_APP_IMAGE_BASE_PATH + p.picture} class="img-fluid" style={{ maxHeight: "30px" }} alt="" />
                                    </div>
                                    <div className="col-2">
                                        {p.name}
                                    </div>
                                    <div className="col-4">
                                        {p.revenue} FCFA
                                    </div>
                                    <div className="col-3">
                                        {p.order} ventes
                                    </div>
                                </div>

                            })}
                        </div>
                    </CardViewer>}
                </div>
                {/*  <div className="col-5 rounded d-none d-sm-block">
                    <CardViewer title="">
                        <Line data={data} options={options} />
                    </CardViewer>
                </div> */}
            </div>
            <div className="row d-flex">

                <div className="col-5 col-sm-3">
                    <button type="button" name="" id="" class="btn btn-warning"><i className="bi bi-activity"></i> Produits unique : {productData.length}</button>
                </div>

                <div className="col-5 col-sm-4">
                    <input value={q} onChange={(e) => setQ(e.target.value)} placeholder='rechercher un produit' type="text" className="form-control mr-sm-2" />
                </div>
                <div className="col col-2 d-none d-sm-block">
                    <div class="form-group">
                        <select value={sortBy} onChange={(e) => setSortBy(e.target.value)} className="custom-select form-control" name="" id="">
                            <option selected value={"date"}>Trier par</option>
                            <option value={"date"}>Date</option>
                            <option value="price">Prix</option>
                            <option value="sold">Vente</option>
                            {false && <option value="quantity">Quantité</option>}
                        </select>
                    </div>
                </div>

                <div className="col-1 d-none d-sm-block">
                    <div class="form-group">
                        <select value={order} onChange={(e) => setOrder(e.target.value)} class="custom-select form-control" name="" id="">
                            <option selected>Ordre</option>
                            <option value="asc">Croissant</option>
                            <option value="desc">Décroissant</option>
                        </select>
                    </div>
                </div>
                <div className="col-1 col-sm-2">
                    <button onClick={() => setShowNewProductModal(!showNewProductModal)} type="button" name="" id="" class="btn btn-primary"><i className="bi bi-plus"></i><div className="d-none d-block">Nouveau produit</div></button>
                </div>


            </div>

            <div style={{ marginRight: '.7em' }}>
                <table class="table table-striped bg-light rounded my-2 table-inverse table-responsive-xs">
                    <thead class="thead-inverse">
                        <tr>
                            <th>#</th>
                            <th>Code</th>
                            <th>Image</th>
                            <th>Désignation</th>
                            <th>Prix</th>
                            {false && <th>Quantité</th>}
                            <th>Vente</th>
                            <th>Apport</th>
                            {userData.is_super_admin &&
                                <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>

                        {productData.length !== 0 &&
                            filterProduct(productData, sortBy, order).map((p, key) => {
                                //const { order, revenue, quantity } = getProductInfo(p)
                                const output = <tr key={key}>
                                    <td>{p.id}</td>
                                    <td>{p.code}</td>
                                    <td>
                                        <img style={{ height: "5vh" }} src={process.env.REACT_APP_IMAGE_BASE_PATH + p.picture} alt="" className='img img-fluid' />
                                    </td>
                                    <td>{p.name}</td>
                                    <td>{p.price} FCFA</td>
                                    {false && <td>quantity</td>}
                                    <td>{p.order}</td>
                                    <td>{p.revenue} FCFA</td>
                                    {userData.is_super_admin && <td>
                                        <div className="row">
                                            <div onClick={() => setProductToEdit(p)} className="col-6"><button type="button" class="btn btn-primary"><span className="bi bi-pencil"></span></button></div>
                                            <div className="col-6"><button type="button" class="btn btn-danger"><span className="bi bi-trash"></span></button></div>
                                        </div>
                                    </td>}
                                </tr>
                                const pName = p.name;
                                if (q !== null && q.length > 0) {
                                    if (pName.toLowerCase().includes(q.toLowerCase())) {
                                        return output
                                    }
                                } else {
                                    return output
                                }


                            })
                        }

                    </tbody>
                </table>
            </div>
        </SideBar>
    )
}
