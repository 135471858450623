import React from 'react'

export const CardViewer = (props) => {


    return (
        <div style={props.style} className={"row text-light rounded " + props.className}>
            <div className="col-12 fs-4 d-flex">
                {props.title}
            </div>
            <div className="col-12 d-flex">
               {props.children}
            </div>
        </div>
    )
}
