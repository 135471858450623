import {
    GET_MESSAGE,
    ADD_MESSAGE,
    DELETE_MESSAGE
} from "../actions/message.action";

const initialState = []

export default function messageReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MESSAGE:
            return state;
        case ADD_MESSAGE:
            state.push({
                message: action.payload.message,
                type: action.payload.type
            });
            return state;
        case DELETE_MESSAGE:
            state = state.filter((m) => {
                return action.payload.message !== m.message
            })
            return state;
        default:
            return state;
    }
}