import axios from "axios"
import { hideLoader, showLoader } from "./loader.action"

export const ADD_CUSTOMER = "ADD_CUSTOMER"
export const GET_CUSTOMERS = "GET_CUSTOMERS"

export const getCustomers = (token) => {
    
    return (dispatch) => {
        dispatch(showLoader())
        axios.get(
            `${process.env.REACT_APP_API_BASE_URL}customers`, {
                headers: {
                    Authorisation: `Bearer ${token}`
                }
            }
        ).then((res) => {
            dispatch({
                type: GET_CUSTOMERS,
                payload: res.data
            })
            dispatch(hideLoader())
        }).catch((err) => {console.log(err); dispatch(hideLoader())})
    }
}

export const addCustomer = (token, customer, onSuccess, onError) => {
    let data = {
        firstname : customer.name,
    }
    if(customer.phone.length===9){
        data = {
            ...data,
            phone: customer.phone
        }
    }
    //console.log(customer)
    return (dispatch) => {
        dispatch(showLoader())
        axios.post(
            `${process.env.REACT_APP_API_BASE_URL}customers`,
            data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then((res) => {
            //console.log(res)
            dispatch({
                type: ADD_CUSTOMER,
                payload: res.data
            })
            onSuccess("", res.data.id)
            dispatch(hideLoader())
        }).catch((err) => {
            console.log(err)
            onError("Une erreur s'est produite, veuillez réessayer.")
            dispatch(hideLoader())
        })
    }
}