import axios from "axios";

import { hideLoader, showLoader } from "./loader.action";

export const GET_COMMERCIALS = "GET_COMMERCIALS";
export const ADD_COMMERCIAL = "ADD_COMMERCIAL";
export const EDIT_COMMERCIAL = "EDIT_COMMERCIAL"


export const getCommercials = (token) => {
    return (dispatch) => {
        dispatch(showLoader())
        axios.get(`${process.env.REACT_APP_API_BASE_URL}users`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            dispatch({
                type: GET_COMMERCIALS,
                payload: res.data
            })
            dispatch(hideLoader())
        }).catch(err => {console.log(err.data); dispatch(hideLoader())});
    }
}

export const addCommercial = (token, user, onSuccess, onError) => {
    return (dispatch) => {
        dispatch(showLoader())
        axios.post(`${process.env.REACT_APP_API_BASE_URL}users`, user, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then((res) => {
                dispatch({
                    type: ADD_COMMERCIAL,
                    payload: res.data
                })
                onSuccess("L'utilisateur a bien été ajouté.")
                dispatch(hideLoader())
            }).catch((err) => {
                console.log(err.response.data)
                onError(err.response.data.message)
                dispatch(hideLoader())
            });
    }
}

export const editCommercial = (token, user, onSuccess, onError) => {
    return (dispatch) => {
        dispatch(showLoader())
        axios.patch(`${process.env.REACT_APP_API_BASE_URL}users/${user.id}`, user, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then((res) => {
                console.log(res.data)
                dispatch({
                    type: EDIT_COMMERCIAL,
                    payload: res.data
                })
                onSuccess("L'utilisateur a bien été modifié.")
                dispatch(hideLoader())
            }).catch((err) => {
                console.log(err.response.data)
                onError(err.response.data.message)
                dispatch(hideLoader())
            });
    }
}