import {useEffect,React} from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../actions/user.action';

export const Logout = () => {
    const dispacth = useDispatch();
    const navigate = useNavigate()
    
    useEffect(() => {
      dispacth(getToken(""));
      window.localStorage.removeItem("token");
      navigate("/account");
    }, [dispacth])
    
  return (
    <div>déconnexion...</div>
  )
}
