import { combineReducers } from 'redux'
import userReducer from './user.reducer'
import productReducer from './product.reducer'
import orderReducer from './order.reducer'
import stockReducer from './stock.reducer'
import messageReducer from './message.reducer'
import stockCommandReducer from './stockcommand.reducer'
import { customerReducer } from './customer.reducer'
import commercialReducer from './commercial.reducer'
import loaderReducer from './loader.reducer'


export default combineReducers(
    {
        userReducer,
        productReducer,
        orderReducer,
        stockReducer,
        messageReducer,
        stockCommandReducer,
        customerReducer,
        commercialReducer,
        loaderReducer
    }
)