import { GET_STOCK } from "../actions/stock.action";

const initialState = {}

export default function stockReducer(state=initialState, action){
    switch (action.type) {
        case GET_STOCK:
            return action.payload;
        default:
            return state;
    }
}