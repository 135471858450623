import {
    GET_ORDER,
    ADD_ORDER,
    UPDATE_ORDER
} from "../actions/order.action";

const initialState = []

export default function orderReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ORDER:
            return action.payload;
        case ADD_ORDER:
            const order = action.payload
            state = {
                ...state,
                order
            }
            return state
        case UPDATE_ORDER:
            const p1 = action.payload
            state = state.map((p0) => {
                return p0.id === p1.id ? p1 : p0
            })
            return state
        default:
            return state;
    }
}