import React from 'react'
import { Modal } from '../utils/Modal'

export const Dialog = (props) => {
  return (
    <div class="Modal" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            
            <div class="modal-body">
                <div class="container-fluid align-items-center align-content-center">
                    {props.message}
                </div>
            </div>
            
        </div>
    </div>
</div>
  )
}
