import './App.css';
import { useEffect, useState } from 'react';
import { Routing } from './components/Routes';
import { LoaderContext, UidContext } from './components/AppContext';
import {useSelector } from 'react-redux';


function App() {
  const token = useSelector((state)=>state.userReducer).token;

  const loadScript = (src)=>{
    const script = document.createElement('script')
    script.async = false
    const body = document.getElementsByTagName('body')[0]
    script.type = "text/javascript"
    script.src = src
    body.appendChild(script)
  }

  useEffect(()=>{
    if(window&&document){
      loadScript("../public/assets/vendor/purecounter/purecounter.js")
      loadScript("../public/assets/vendor/aos/aos.js")
      loadScript("../public/assets/vendor/bootstrap/js/bootstrap.bundle.min.js")
      loadScript("../public/assets/vendor/glightbox/js/glightbox.min.js")
      loadScript("../public/assets/vendor/isotope-layout/isotope.pkgd.min.js")
      loadScript("../public/assets/vendor/swiper/swiper-bundle.min.js")
      loadScript("../public/assets/vendor/php-email-form/validate.js")
      loadScript("../public/assets/js/main.js")
    }

  }, [])

  const [loaderContext, setLoaderContext] = useState(false)

  return (
    <div className="App">
    <UidContext.Provider value={token}>
      <LoaderContext.Provider value={[loaderContext, setLoaderContext]}>
         <Routing/>
      </LoaderContext.Provider>
    </UidContext.Provider>
      
    </div>
  );
}

export default App;
