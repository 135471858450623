import {
    GET_USER,
    GET_TOKEN,
} from "../actions/user.action"

const initialState = {};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case GET_USER:
            return action.payload;
        case GET_TOKEN:
            return {
                ...state,
                token: action.payload
            }
            default:
                return state;
    }

}