import axios from "axios";
import {
    format
} from 'date-fns'
import { hideLoader, showLoader } from "./loader.action";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT"


export const getProducts = (token, startDate = format(new Date(), "yyyy-MM-dd"), endDate = format(new Date(), "yyyy-MM-dd")) => {
    return (dispatch) => {
        dispatch(showLoader())
        axios.get(`${process.env.REACT_APP_API_BASE_URL}products`, {
            params: {
                startDate,
                endDate
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(hideLoader())
            dispatch({
                type: GET_PRODUCTS,
                payload: res.data
            })
        }).catch(err => {console.log(err.data); dispatch(hideLoader())});
    }
}

export const getProduct = (token, id) => {
    return (dispatch) => {
        axios({
            url: `${process.env.REACT_APP_BASE_URL}products/${id}`,
            method: 'get',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            dispatch({
                type: GET_PRODUCT,
                payload: res.data
            })
        }).catch(err => console.log(err.data));
    }
}

export const addProduct = (token, product, onSuccess, onError) => {
    return (dispatch) => {
        dispatch(showLoader())
        axios.post(`${process.env.REACT_APP_API_BASE_URL}products`, product, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then((res) => {
                dispatch({
                    type: ADD_PRODUCT,
                    payload: res.data
                })
                onSuccess("Le produit a bien été ajouté.")
                dispatch(hideLoader())
            }).catch((err) => {
                console.log(err.response.data)
                onError(err.response.data.message)
                dispatch(hideLoader())
            });
    }
}

export const editProduct = (token, product, onSuccess, onError) => {
    return (dispatch) => {
        dispatch(showLoader())
        axios.patch(`${process.env.REACT_APP_API_BASE_URL}products/${product.id}`, product, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then((res) => {
                console.log(res.data)
                dispatch({
                    type: EDIT_PRODUCT,
                    payload: res.data
                })
                onSuccess("Le produit a bien été modifié.")
                dispatch(hideLoader())
            }).catch((err) => {
                console.log(err.response.data)
                onError(err.response.data.message)
                dispatch(hideLoader())
            });
    }
}