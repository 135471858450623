import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { About } from '../../pages/About';
import { Account } from '../../pages/Account';
import { Home as DashboardHome } from '../../components/Account/Dashboard/Home';
import { Product as  DashboardProduct} from '../Account/Dashboard/Product';
import { Shop as DashboardShop } from '../Account/Dashboard/Shop';
import { Sale as DashboardSale } from '../Account/Dashboard/Sale';
import { Accounting as DashboardAccounting } from '../Account/Dashboard/Accounting';
import { Stock as DashboardStock } from '../Account/Dashboard/Stock';
import { Commercial as DashboardCommercial } from '../Account/Dashboard/Commercial';
import { Login } from '../../pages/Login';
import { Logout } from '../../pages/Logout';
import { Register } from '../../pages/Register';
import { Navbar } from '../Navbar';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

export const Routing = () => {
    const userData = useSelector((state) => state.userReducer);

    return (
        <Router>
            <Helmet>
                <title>Boutique</title>
                <meta name="title" content='Boutique'/>
            </Helmet>
            {(userData.name === null || userData.name === undefined) && false&&
                <Navbar />}

            <Routes>
                <Route path="/" exact element={<Account />}></Route> 
                <Route path="/login" exact element={<Login />}></Route>
                <Route path="/logout" exact element={<Logout />}></Route>
                {false&&<Route path="/register" exact element={<Register />}></Route>}
                {false&&<Route path="/account" exact element={<Account />}></Route>}
                <Route path="/home" exact element={<DashboardHome />}></Route>
                <Route path="/products" exact element={<DashboardProduct />}></Route>
                <Route path="/shop" exact element={<DashboardShop />}></Route>
                <Route path="/sales" exact element={<DashboardSale />}></Route>
                <Route path="/stock" exact element={<DashboardStock />}></Route>
                <Route path="/accounting" exact element={<DashboardAccounting />}></Route>
                <Route path="/commercial" exact element={<DashboardCommercial />}></Route>

                {false&&<Route path="/about" exact element={<About />}></Route>}
                <Route path="*" exact element={<Account />}></Route>
            </Routes>

        </Router>
    )
}
