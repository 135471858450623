import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';

export const DateRangePicker = (props) => {
  const initialSelection = props.selection??{
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    period: 'today',
  };
  const [startDate, setStartDate] = useState(initialSelection.startDate);
  const [endDate, setEndDate] = useState(initialSelection.endDate);
  const [period, setPeriod] = useState(initialSelection.period);
  const [perso, setPerso] = useState(false);

  let startDateNew = startDate;
  let endDateNew = endDate;
  let persoNew = perso;

  const handleSelect = (e) => {
    const val = e.target.value;
    persoNew = val;
    setPeriod(persoNew);
    const now = new Date();
    val === "perso" ? setPerso(true) : setPerso(false);

    switch (val) {
      case "today":
        setStartDate(format(new Date(), "yyyy-MM-dd"))
        setEndDate(format(new Date(), "yyyy-MM-dd"))
        onChange(format(new Date(), "yyyy-MM-dd"), format(new Date(), "yyyy-MM-dd"));
        break;
      case "yesterday":
        setStartDate(format((new Date()).setDate((new Date()).getDate() - 1), "yyyy-MM-dd"))
        setEndDate(startDate)
        onChange(format((new Date()).setDate((new Date()).getDate() - 1), "yyyy-MM-dd"), format((new Date()).setDate((new Date()).getDate() - 1), "yyyy-MM-dd"))
        break;
      case "week":
        setStartDate(format((new Date()).setDate((new Date()).getDate() - 7), "yyyy-MM-dd"))
        setEndDate(format(new Date(), "yyyy-MM-dd"))
        onChange(format((new Date()).setDate((new Date()).getDate() - 7), "yyyy-MM-dd"), format(new Date(), "yyyy-MM-dd"))
        break;
      case "month":
        setStartDate(format((new Date()).setDate((new Date()).getDate() - 28), "yyyy-MM-dd"))
        setEndDate(format((new Date()), "yyyy-MM-dd"))
        onChange(format((new Date()).setDate((new Date()).getDate() - 28), "yyyy-MM-dd"), format((new Date()), "yyyy-MM-dd"))
        break;
      default:
        break;
    }
    //onChange(startDateNew, endDateNew);
  }  

  const onChange = (startDateNew, endDateNew)=>{
    props.onChange({
      startDate:  startDateNew,
      endDate: endDateNew,
      period: persoNew
    })
  }

  return (
    <div className="row m-2">
      <div className="col-5 col-sm-2">
        <select value={period} onChange={(e) => handleSelect(e)} className="form-control">
          <option value="today">Aujourd'hui</option>
          <option value="yesterday">Hier</option>
          <option value="week">7 derniers jours</option>
          <option value="month">28 derniers jours</option>
          <option value="perso">Personnalisé</option>
        </select>
      </div>
      <form className="col-7 col-sm-5">
        {perso&&<div className="row">
          <div className="col-6">
            <input onChange={(e)=>{startDateNew=e.target.value;setStartDate(startDateNew); onChange(startDateNew, endDateNew)}} max={endDateNew} name="_start_date" className="form-control" value={startDate} type="date" />
          </div>
          <div className="col-6">
            <input onChange={(e)=>{endDateNew=e.target.value;setEndDate(endDateNew); onChange(startDateNew, endDateNew)}} min={startDateNew} max={format(new Date(), "yyyy-MM-dd")} name="_end_date" className="form-control" value={endDate} type="date" />
          </div>

        </div>}
      </form>
    </div>

  )
}
