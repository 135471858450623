
export const GET_MESSAGE = "GET_MESSAGE";
export const ADD_MESSAGE =  "ADD_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";


export const getMessages = ()=>{
    return (dispatch)=>{
        dispatch({
            type: GET_MESSAGE
        })
    }
}

export const addMessage = (message, type)=>{
    return (dispatch)=>{
         dispatch({
            type: ADD_MESSAGE,
            payload: {
                message,type
            }
        })
    }
}

export const deleteMessage = (message, type)=>{
    return (dispatch)=>{
        //console.log(message);
        dispatch({
            type: DELETE_MESSAGE,
            payload: {
                message,type
            }
        })
    }
}