import React, { useState, useRef, useEffect } from 'react'
import { Modal } from '../../../utils/Modal'
import { useDispatch } from 'react-redux'
import { addMessage} from '../../../../actions/message.action'
import { addCommercial, getCommercials } from '../../../../actions/commercial.action'

export const NewCommercial = (props) => {
    const formRef = useRef(null)
    const [name, setName] = useState()
    const [mail, setMail] = useState()
    const [password, setPassword] = useState()
    const token = window.localStorage.getItem("token")
    const [error, setError] = useState(false)

    const dispatch = useDispatch()

    const handleSave = async () => {
        setError(false)
        const data = new FormData(formRef.current);
        let commercial = {
            name: data.get("name"),
            email: data.get("mail"),
            password: data.get("password"),
            device_name: "Andorid",
            is_admin: true
        }

        //console.log(commercial)
        try {
            dispatch(addCommercial(token,
                commercial,
                (message) => {
                    dispatch(getCommercials(token) )
                    dispatch(addMessage(message, "success")); props.onDismiss();
                },
                (message) => {
                    //dispatch(addMessage(message, "danger"))
                    setError(message)
                }),

            )

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

    }, [])



    return (
        <Modal title={props.title} onSave={() => { handleSave() }} onDismiss={(e) => { props.onDismiss() }} saveTitle={<><small className='bi bi-save'></small> AJOUTER</>}>
            <form method='post' ref={formRef} enctype="multipart/form-data" className="row mb-1">
                {error && <div className="alert alert-danger">
                    {error}
                </div>}
               
                <div className="row mb-1 gap-2">
                    <div className="col-10">
                        <input required name='name' type="text" class="form-control" placeholder='nom du commercial' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="col-10">
                        <input required name='mail' type="mail" class="form-control" placeholder='email du commercial' value={mail} onChange={(e) => setMail(e.target.value)} />
                    </div>
                    <div className="col-10">
                        <input required name='password' type="password" class="form-control" placeholder='mot de passe du commercial' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                </div>
            </form>

        </Modal>
    )
}
