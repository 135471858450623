import React from 'react'

export const PageInfo = (props) => {
  return (
    <div className="PageInfo row border-bottom">
        <span className='text-black-50 fs-3 fw-bolder d-flex'>{props.title}</span>
        <p className='text-muted small d-flex'>{props.description}</p>
    </div>
  )
}
