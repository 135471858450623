import React, { useState, useRef, useEffect } from 'react'
import { Modal } from '../../../utils/Modal'
import { useDispatch } from 'react-redux'
import { addProduct } from '../../../../actions/product.action'
import { addMessage } from '../../../../actions/message.action'
import { getStock } from '../../../../actions/stock.action'

export const NewProduct = (props) => {
    const [file, setFile] = useState(null)
    const fileInput = useRef(null)
    const formRef = useRef(null)

    const [name, setName] = useState()
    const [price, setPrice] = useState(0)
    const [picture, setPicture] = useState()
    const [code, setCode] = useState()
    const token = window.localStorage.getItem("token")
    const [error, setError] = useState(false)

    const dispatch = useDispatch()

    const handleChange = (img) => {
        setPicture(img)
        const reader = new FileReader()
        reader.onload = (e) => {
            setFile(e.target.result)
        }
        reader.readAsDataURL(img)
    }


    const handleSave = async () => {
        setError(false)
        const data = new FormData(formRef.current);
        let product = {
            name: data.get("name"),
            description: data.get("description"),
            code: data.get("code"),
            price: data.get("price"),
            picture: file
        }

        //console.log(product)
        try {
            dispatch(addProduct(token,
                product,
                (message) => {
                    dispatch(getStock(token) )
                    dispatch(addMessage(message, "success")); props.onDismiss();
                },
                (message) => {
                    //dispatch(addMessage(message, "danger"))
                    setError(message)
                }),

            )

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

    }, [])



    return (
        <Modal title={props.title} onSave={() => { handleSave() }} onDismiss={(e) => { props.onDismiss() }} saveTitle={<><small className='bi bi-save'></small> AJOUTER</>}>
            <form method='post' ref={formRef} enctype="multipart/form-data" className="row mb-1">
                {error && <div className="alert alert-danger">
                    {error}
                </div>}
                <div className="row m-0">
                    <input onChange={(e) => handleChange(e.target.files[0])} ref={fileInput} hidden type="file" name="picture" id="file" accept=".png,.jpg,.jpge,.svg" />
                    <div draggable style={{ cursor: "pointer", borderStyle: "dashed" }} onClick={() => fileInput.current.click()} className="mb-1 border-1 rounded dashed primary p-3 text-black-50">
                        {file === null && <>SELECTIONNER OU DEPOSER UNE IMAGE</>}
                        {file !== null && <img src={file} class="img-fluid" style={{ width: "50px", height: "50px" }} alt="" />}
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-8">
                        <input name='name' type="text" class="form-control" placeholder='nom du produit' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="col-3">
                        <input name='price' type="number" class="form-control" min={0} value={price} onChange={(e) => setPrice(e.target.value)} />
                    </div>
                    <div className="col-1 mt-2 text-black-50">
                        FCFA
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-8">
                        <input name='code' type="text" class="form-control" placeholder='code du produit' value={code} onChange={(e) => setCode(e.target.value)} />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-12">
                        <div class="form-group">
                            <label for=""></label>
                            <textarea class="form-control" name="description" id="" rows="3" placeholder='Veuillez décrire ce produit'></textarea>
                        </div>
                    </div>

                </div>

            </form>

        </Modal>
    )
}
