import React, { useEffect, useState } from 'react'
import { SideBar } from '../../Navbar/SideBar'
import { CardAnalytic } from './CardAnalytic'
import { DateRangePicker } from './../../utils/DateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../../../actions/order.action';
import { format } from 'date-fns';
import { PageInfo } from '../../utils/PageInfo';
import { priceFormat } from '../../../utils/utils';


export const Home = () => {
  const cls = "col col-xs-12 col-sm-12 col-md-4 col-lg-3 rounded";
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    period: 'today',
  });

  const [comparatorPeriod, setComparatorPeriod] = useState();
  const [q, setQ] = useState(null);
  const dispatch = useDispatch();
  const orderData = useSelector((state) => state.orderReducer);
  const token = window.localStorage.getItem("token");

  const handleOnDateRangeChange = (selection) => {
    setDateRange(selection);
    dispatch(getOrders(token, selection.startDate, selection.endDate))

    if (selection.period === "perso") { setComparatorPeriod("à la période précédente") }
    else if (selection.period === "today") { setComparatorPeriod("à Hier") }
    else if (selection.period === "yesterday") { setComparatorPeriod("à Avant-Hier") }
    else if (selection.period === "week") { setComparatorPeriod("à la semaine précédente") }
    else if (selection.period === "month") { setComparatorPeriod("au mois précédent") }


  }

  useEffect(() => {
    dispatch(getOrders(token))
  }, [])

  const getRevenue = () => {
    let revenue = 0
    for (const order of orderData) {
      revenue += order.revenue
    }
    return revenue
  }

  const getProfit = () => {
    let profit = 0
    for (const order of orderData) {
      profit += order.profit
    }
    return profit
  }

  const getNumberOfProduct = () => {
    let numberOfProduct = 0
    for (const order of orderData) {
      numberOfProduct += order.products.length
    }
    return numberOfProduct
  }

  return (
    <SideBar>
      <PageInfo
        title="Tableau de bord"
        description="" />

      <div className="row">
        <div className="col-10">
          <DateRangePicker onChange={handleOnDateRangeChange} />
        </div>
      </div>
      <div className="d-flex row m-3 gap-4">
        <CardAnalytic
          style={{ backgroundColor: '#00897b' }}
          value={orderData.length}
          unity={""}
          name={"Nombre de ventes"}
          comparator={
            {
              up: false,
              percent: 3.37,
              period: comparatorPeriod
            }
          }
          className={cls} />
        <CardAnalytic
          style={{ backgroundColor: '#4b22e9' }}
          value={getNumberOfProduct()}
          unity={""}
          name={"Produits vendus"}
          comparator={
            {
              up: false,
              percent: 10.50,
              period: comparatorPeriod
            }
          }
          className={cls} />
        <CardAnalytic
          style={{ backgroundColor: '#ff6f00' }}
          value={priceFormat(getRevenue())}
          name={"Revenus estimés"}
          comparator={
            {
              up: true,
              percent: 11.23,
              period: comparatorPeriod
            }
          }
          className={cls} />
        <CardAnalytic
          style={{ backgroundColor: '#333333' }}
          value={priceFormat(getProfit())} 
          name={"Bénéfice estimés"}
          comparator={
            {
              up: true,
              percent: 11.23,
              period: comparatorPeriod
            }
          }
          className={cls} />
      </div>
      <div className="row p-0 m-0 d-flex">
        <div className="p-0 m-0 col"></div>
        <div className="col col-6">
          <input value={q} onChange={(e) => setQ(e.target.value)} placeholder='rechercher un produit ou un client' type="text" className="form-control mr-sm-2" />
        </div>

        <div className="col col-2">
          <div class="form-group">
            <select class="custom-select form-control" name="" id="">
              <option selected value={"date"}>Trier par</option>
              <option value={"date"}>Date</option>
              <option value="price">Prix</option>
              <option value="sum">Total</option>
              <option value="quantity">Quantité</option>
            </select>
          </div>
        </div>

        <div className="col col-1">
          <div class="form-group">
            <select value="asc" class="custom-select form-control" name="" id="">
              <option>Ordre</option>
              <option value="asc">Croissant</option>
              <option value="desc">Décroissant</option>
            </select>
          </div>
        </div>

      </div>
      <div className="table-responsive-sm bg-light my-2 rounded" style={{marginRight:'.7em'}}>
        <table className="table table-hover table-striped table-inverse table-responsive-sm">
          <thead className="thead-inverse">
            <tr>
              <th>#</th>
              <th>Status</th>
              <th>Date</th>
              <th>Désignation</th>
              <th>Quantité</th>
              <th>Prix</th>

              <th>Client</th>
              <th>Vendeur</th>

            </tr>
          </thead>
          <tbody>
            {orderData && orderData.length !== 0 && orderData.map((o, key) => {

              const output = <tr key={key}>
                <td>{o.id}</td>
                <td className={o.status === "pending" ? "text-danger" : "text-success"}>{o.status === "pending" ? "impayé" : "payé"}</td>
                <td>{format(new Date(o.updated_at), "dd/MM/yyyy à H:ss")}</td>
                <td>{o.names}</td>
                <td>{o.products.length}</td>
                <td>{priceFormat(o.price)}</td>
                <td>{o.customer.firstname}</td>
                <td>{o.user.name}</td>
              </tr>
              const oName = o.names + "," + o.customer.firstname
              if (q !== null && q.length > 0) {
                if (oName.toLowerCase().includes(q.toLowerCase())) {
                  return output
                }
              } else {
                return output
              }
            })}

          </tbody>
        </table>
      </div>

    </SideBar>
  )
}
