import React from 'react'
import { useState } from 'react'
import { Modal } from '../../../utils/Modal'
import { format } from 'date-fns'
import { mapOrder } from '../../../../utils/utils'

export const DEFAULT = "DEFAULT"
export const PROFORMA = "PROFORMA"
export const DELIVERY = "DELIVERY"
export const INVOICE = "INVOICE"

export const NewBilling = (props) => {
  const [billConfig, setBillConfigs] = useState(props.options)
  const setBillConfig = (config) => {
    props.onBillConfigChange(config)
    setBillConfigs(config)
  }

  return (
    <Modal className="" title={props.title} onSave={() => { props.onSave() }} onDismiss={(e) => { props.onDismiss() }} saveTitle={<><i className='bi bi-printer'></i> Générer</>}>

      <div className="row">
        <div class="form-group">
          <select onChange={(e) => setBillConfig({ ...billConfig, proforma: e.target.value === PROFORMA, delivery: e.target.value === DELIVERY, invoice: e.target.value === INVOICE })} class="form-control" name="" id="">
            <option value={DEFAULT}>Par défaut</option>
            <option value={INVOICE}>Entreprise</option>
            <option value={PROFORMA}>Proformat</option>
            <option value={DELIVERY}>Bordereaux</option>
          </select>
        </div>
        <div className="row mt-1">
          <div className="col-2">TVA : </div>
          <div className="col-10"><input max={100} min={0} onChange={(e) => setBillConfig({ ...billConfig, tva: e.target.value })} value={billConfig.tva} type="number" className="form-control" /></div>
        </div>
        <div className="row mt-1">
          <div className="col-2">IR : </div>
          <div className="col-10"><input max={100} min={0} onChange={(e) => setBillConfig({ ...billConfig, ir: e.target.value })} value={billConfig.ir} type="number" className="form-control" /></div>
        </div>
        <div className="row mt-1">
          <div className="col-3">Echéance : </div>
          <div className="col-9"><input onChange={(e) => setBillConfig({ ...billConfig, deadline: e.target.value })} value={format(new Date(billConfig.deadline), "yyyy-MM-dd")} type="date" className="form-control" /></div>
        </div>

        {(billConfig.proforma || billConfig.invoice) && <div className="row mt-1">
          <div className="col-3">Adresse : </div>
          <div className="col-9">
            <textarea
              onChange={(e) => setBillConfig({ ...billConfig, proforma: true, address: e.target.value })}
              class="form-control"
              name="" id=""
              value={billConfig.address}
              rows="3" >{billConfig.address}</textarea>
          </div>
          <div className="col-2 mt-1">Prix: </div>
          <div className="col-10 mt-1"><input autoCapitalize='on' style={{ textTransform: "uppercase" }} placeholder='prix en toutes lettre' onChange={(e) => setBillConfig({ ...billConfig, price: e.target.value })} value={billConfig.price} type="text" className="form-control" /></div>

        </div>}

        {(billConfig.delivery || billConfig.invoice) && <div className="row mt-1">
          {billConfig.delivery && <> <div className="col-3">Adresse : </div>
            <div className="col-9">
              <textarea
                onChange={(e) => setBillConfig({ ...billConfig, proforma: true, address: e.target.value })}
                class="form-control"
                name="" id=""
                value={billConfig.address}
                rows="3" >{billConfig.address}</textarea>
            </div> </>}
          <div className='row'>
            <div className="col-2 mt-1 small"> N° BL: </div>
            <div className="col-10 mt-1"><input placeholder='numéro de bordereaux' onChange={(e) => setBillConfig({ ...billConfig, deliveryNumber: e.target.value })} value={billConfig.deliveryNumber} type="text" className="form-control" /></div>
          </div>
          <div className='row'>
            <div className="col-2 mt-1 small"> N° BC: </div>
            <div className="col-10 mt-1"><input placeholder='numéro de bon de commande' onChange={(e) => setBillConfig({ ...billConfig, bc: e.target.value })} value={billConfig.bc} type="text" className="form-control" /></div>
          </div>

        </div>}
      </div>


    </Modal>
  )
}
