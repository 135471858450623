import React, { forwardRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { getCommercials } from '../../actions/commercial.action';
import { getMessages } from '../../actions/message.action';
import { getOrders } from '../../actions/order.action';
import { getProducts } from '../../actions/product.action';
import { getStock } from '../../actions/stock.action';
import { getStockCommand } from '../../actions/stockcommand.action';
import './DashboardHeader.css';


export const DashboardHeader = forwardRef((props, ref) => {
  const userData = useSelector((state) => state.userReducer);
  const commercialData = useSelector((state) => state.commercialReducer)
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("token");
  const navigate = useNavigate();

  const location = useLocation();


  const handleReload = async (e) => {
    try {
      dispatch(getMessages())
      dispatch(getProducts(token))
      dispatch(getOrders(token))
      dispatch(getStock(token))
      dispatch(getStockCommand(token))
      dispatch(getCommercials(token))

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleReload();
  }, [token])

  return (
    <>

      <header id="header" class="d-flex align-items-center justify-content-between text-dark" style={{ backgroundColor: "inherit", border: "none", boxShadow: "none" }}>
        {commercialData.length && location.pathname === "/" && navigate("/home")}

        <div className="d-flex align-items-center justify-content-between w-100 mx-2">
          <div ref={ref} className='toggleBtn'>
            <div className='toggleBtnContent'>
              <i className='bi bi-list text-light fs-2'></i>
            </div>
          </div>

          <div class="dropdown border d-flex mr-4 bg-light rounded px-3">
            <a style={{ gap: '.3em' }} href="#" class="d-flex align-items-center text-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="bi bi-person-circle fs-2 text-black-50"></i>
              <span class="d-none d-sm-inline text-black-50">{userData.name}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
              {false && <li><a class="dropdown-item" href="#">Message</a></li>
              }
              {false && <li><hr class="dropdown-divider text-dark bg-dark" />
              </li>}
              <li><NavLink exact to="/logout" className="nav-link scrollto text-white">Déconnexion</NavLink></li>
            </ul>
          </div>
        </div>
      </header>

    </>
  )
})
