import { ADD_CUSTOMER, GET_CUSTOMERS } from "../actions/customer.action";


const initialState = []
export const customerReducer = (state=initialState, action)=>{
    switch (action.type) {
        case GET_CUSTOMERS:
            return action.payload
        case ADD_CUSTOMER:
            const customer = action.payload
            state = {
                ...state,
                customer
            }
            return state
        default:
            return state;
    }
}