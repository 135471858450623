import axios from "axios";
import { format } from 'date-fns';
import { hideLoader, showLoader } from "./loader.action";


export const GET_ORDER = "GET_ORDER";
export const ADD_ORDER = "ADD_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";

export const addOrder = (token, products, onSuccess, onError) => {
    console.log(products)
    return (dispatch) => {
        axios.post(
            `${process.env.REACT_APP_API_BASE_URL}orders`,
            products, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        ).then((res) => {
            //console.log(res.data)
            dispatch({
                type: ADD_ORDER,
                payload: res.data
            })
            onSuccess("La vente a été effectué, vous pouvez imprimer la facture dans l'onglet Vente.")
            //dispatch(addMessage("Le produit a bien été ajouté.", "success"))
        }).catch((err) => {
            console.log(err.response.data)
            onError(err.response.data.message)
            //dispatch(addMessage(err.response.data.message, "danger"));
        });
    }
}



export const getOrders = (token, startDate = format(new Date(), "yyyy-MM-dd"), endDate = format(new Date(), "yyyy-MM-dd"), userId = 0, byOrder = false) => {
    //console.log(startDate, endDate)
    let params = {
        startDate,
        endDate,
        userId,
        user_id: userId
    };
    if(byOrder) params={...params, by_order:byOrder}
    return (dispatch) => {
        dispatch(showLoader())
        axios.get(`${process.env.REACT_APP_API_BASE_URL}orders`, {
            params: {
                ...params
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            dispatch({
                type: GET_ORDER,
                payload: res.data
            })
            dispatch(hideLoader())
            //console.log(res.data)
        }).catch(err => { console.log(err.data); dispatch(hideLoader()) });
    }
}

export const updateOrder = (token, order, onSuccess, onError) => {

    return (dispatch) => {
        dispatch(showLoader())
        axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}orders/${order.id}`,
            order, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        ).then((res) => {
            //console.log(res.data)
            dispatch({
                type: UPDATE_ORDER,
                payload: res.data
            })
            onSuccess("La facture a été réglé.")
            dispatch(hideLoader())
        }).catch((err) => {
            console.log(err.response.data)
            onError(err.response.data.message)
            dispatch(hideLoader())
        });
    }
}