import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOrders } from '../../../actions/order.action'
import { SideBar } from '../../Navbar/SideBar'
import { DateRangePicker } from '../../utils/DateRangePicker'
import { format } from 'date-fns'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import './Accounting.scss'
import { PageInfo } from '../../utils/PageInfo'
import { priceFormat } from '../../../utils/utils'


export const Accounting = () => {
  const dispatch = useDispatch()
  const token = window.localStorage.getItem("token")
  const orderData = (useSelector((state) => state.orderReducer));
  const userData = useSelector((state) => state.userReducer)
  const commercialData = useSelector((state) => state.commercialReducer)
  const [commercialId, setCommercialId] = useState(0)

  const [revenue, setRevenue] = useState(0)
  const [profit, setProfit] = useState(0)
  const [numberOfProduct, setNumberOfProduct] = useState(0)
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    period: 'today',
  });
  const accountingRef = useRef(null);

  const handleOnDateRangeChange = (selection) => {
    setDateRange(selection)
    //dispatch(getOrders(token, selection.startDate, selection.endDate))
    dispatch(getOrders(token, selection.startDate, selection.endDate, commercialId, true))
    //dispatch(getProducts(token, selection.startDate, selection.endDate))
    //setProfit(getProfit())
  }
  const handleChangeCommercial = (id) => {
    setCommercialId(id)
    dispatch(getOrders(token, dateRange.startDate, dateRange.endDate, id, true))
    //setProfit(getProfit())
  }

  const filterOrderData = (data) => {
    return data.filter(({ status }) => status !== "pending")
  }

  const getRevenue = () => {
    let revenue = 0
    for (const order of filterOrderData(orderData)) {
      revenue += order.revenue
    }
    return revenue
  }

  const getNumberOfProduct = () => {
    let numberOfProduct = 0
    for (const order of filterOrderData(orderData)) {
      numberOfProduct += order.products.length
    }
    return numberOfProduct
  }

  const getProfit = () => {
    let pf = 0
    if (filterOrderData(orderData)) {
      orderData.map((o, key) => {
        pf = pf + o.profit

      })
    }
    return pf
  }

  useEffect(() => {
    setRevenue(getRevenue())
    setNumberOfProduct(getNumberOfProduct())
    setProfit(getProfit())

  }, [orderData])

  /**const getProductInfo = (product) => {
    const orders = orderData.slice()
    let order = 0;
    let rev = 0;
    orders.map((o) => {
      const products = o.products;
      products.map((p) => {
        if (p.id === product.id) {
          order++
          rev += parseInt(p.price)
        }
        //return p
      })
      //return o
    })

    return { order, rev }
  }**/
  const handlePrint = () => {
    const content = accountingRef.current
    html2canvas(content).then((canvas) => {
      //content.appendChild(canvas)
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: [210, 297]
      });
      pdf.addImage(canvas.toDataURL("image/png", 1.0), 0, 0)
      pdf.save("accounting.pdf")
    })

  }

  return (
    <SideBar>
      <PageInfo
        title="Expert comptable"
        description="Bénéficiez d'une assistance pour faire vos comptes(cacul du chiffre d'affaire, ventes, bénéfices) personnalisables en fonction des commerciaux sur une période donnée." />

      <div className="row">
        <div className="col-10">
          <DateRangePicker onChange={handleOnDateRangeChange} />
        </div>

        {false && <div className="col-2 mt-2">
          <button onClick={() => handlePrint()} type="button" class="btn btn-primary">Imprimer <i className="bi bi-printer"></i></button>
        </div>}
      </div>
      {userData.is_super_admin === 1 && <div className="d-flex justify-content-end align-items-center mr-4">
        <div className='col-5 col-lg-3  me-4'>
          <select onChange={(e) => handleChangeCommercial(e.target.value)} className='form-control mt-2' name="" id="">
            <option value="0">Tous les commerciaux</option>
            {commercialData.length > 0 && commercialData.map((cd, key) => {
              return <option value={cd.id} key={key}>{cd.name}</option>
            })}
          </select>
        </div>
      </div>}
      <div className="row">
        <div className="col-12">
          <div ref={accountingRef} id="accounting" className="row bg-light rounded m-1 m-sm-4 p-4">

            <div className="row mt-2">
              <div className="col-3 mt-3 mr-4">
                <div className="row">
                  <div className="col-1"><img className='img-fluid' width="50" height="50" class="rounded-circle" src="./../../assets/logo.png" alt="" /></div>
                  <div className="col-10 fw-bolder mt-3"><h6>KFA Chimie Sarl</h6></div>
                </div>
              </div>
              <div className="col-8 mt-4 text-uppercase"><h5>COMPATBILITé des produits</h5></div>
            </div>
            <div className="row">
              <div className="col-1">  </div>
              <div className="col-4">Du : {format(new Date(dateRange.startDate), "dd/MM/yyyy")}</div>
              <div className="col-1">  </div>
              <div className="col-4">Au : {format(new Date(dateRange.endDate), "dd/MM/yyyy")}</div>

            </div>
            <div className="row">
              <div className="col-12  border-bottom d-flex mt-1"></div>
            </div>
            <table class="table bg-light table-striped table-inverse table-responsive-xs mt-3">
              <thead class="thead-inverse">
                <tr style={{ fontWeight: 'normal' }}>
                  <th className='text-'>#</th>
                  <th>Désignation</th>
                  <th>Prix Moyen</th>
                  <th>Quantité</th>
                  <th>CA</th>
                  {userData.is_super_admin === 1 && <th>Bénéfice</th>}
                </tr>
              </thead>
              <tbody>

                {filterOrderData(orderData).length !== 0 &&
                  filterOrderData(orderData).map((o, key) => {
                    return <tr key={key}>
                      <td>{key + 1}</td>
                      <td>{o.names}</td>
                      <td>{priceFormat(o.price)}</td>
                      <td>{o.products.length}</td>
                      <td>{priceFormat(o.revenue)}</td>
                      {userData.is_super_admin === 1 && <td>{priceFormat(o.profit)}</td>}
                    </tr>

                  })
                }

              </tbody>
            </table>
            <div className="row mt-2 text-uppercase font-weight-bold" style={{ fontWeight: 'bold' }}>
              <div className="col-3 ">ventes : {filterOrderData(orderData)?.length}</div>
              <div className="col-3">CA : {priceFormat(revenue)}</div>
              <div className="col-3">Produits vendus : {numberOfProduct}</div>
              {userData.is_super_admin === 1 && <div className="col-3">Bénéfice : {priceFormat(profit)}</div>}

            </div>


          </div>
        </div>
      </div>


    </SideBar>
  )
}
