import React from 'react'
import { format } from 'date-fns';
import './Billing.scss';
import { mapOrder, priceFormat } from '../../../../utils/utils';

export const Billing = (props) => {
    const order = mapOrder(props.order)
    return (
        <div className='Billing m-2 ' style={{ width: "210mm", height: "297mm" }}>
         {/* <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>  */}
            <div className="row text-center m-2">
                <div className="col-12
                 small">
                    RCN°RC/DLA/2015/B/963 du 05/03/2015 |
                    NIU: M031512289009 H |
                    B.P 1194 DOUALA-CAMEROUN
                </div>
            </div>
            <div className="row m-2 gap-1 text-center" style={{ fontSize: "0.5em" }}>
                <div className="col-3">TEL: +237698964499/+237699975918</div>
                <div className="col-4">CONTACT: https://www.kfachimie.com,kfachimiesarl@yahoo.fr</div>

                <div className="col-4">LIEU: Douala Terminus, Saint Michel, Rue Barcelone</div>
            </div>
            <hr className='m-2' />
            <div className="row m-2 text-right">
                <div className="col-2 m-3"><img alt="logo" src="./../assets/logo.png" className='img-fluid w-100 ' /></div>
                <div className="col-4 mt-3">
                    <div className="row">
                        {props.options.proforma &&
                            <div style={{ textAlign: "left", border: "1px solid #999" }} className="d-flex flex-column">
                                <div dangerouslySetInnerHTML={{ __html: props.options.address }}>
                                    {/* <b>ENTREPRISE</b><br />
                                    <b>BP:</b> 56552 Douala<br />
                                    <b>NIU:</b> 533415353435<br /> */}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-5" >
                    <div className="d-flex flex-column" style={{ fontSize: "0.7em", marginLeft: "1.3em" }}>
                        {!props.options.delivery&&<h2 style={{ textAlign: "left" }} className='fw-bold'>FACTURE{props.options.proforma&&!props.options.invoice&&" PROFORMAT"}</h2>}
                        {props.options.delivery&&<h2 style={{ textAlign: "left" }} className='fw-bold'>BORDEREAUX DE LIVRAISON</h2>}
                        <span style={{ textAlign: "left" }}><span className="fw-bold">Numéro de facture</span> : FAC-{props.order.id}</span>
                        {props.options.delivery&&<span style={{ textAlign: "left" }}><span className="fw-bold">Numéro de bodereaux</span> : {props.options.deliveryNumber}</span>}

                        <span style={{ textAlign: "left" }}><span className="fw-bold text-left">Date de facturation</span> : {format(new Date(props.order.created_at), "dd-MM-yyyy")}</span>
                        
                        <br/><br/>
                        {props.options.invoice&&<span style={{ textAlign: "left" }}>Réf.</span>}
                        {props.options.invoice&&<span style={{ textAlign: "left" }}>BC. N°{props.options.bc} du {format(new Date(props.order.created_at), "dd-MM-yyyy")}</span>}
                        {props.options.invoice&&<span style={{ textAlign: "left" }}>BL N°{props.options.deliveryNumber} du {format(new Date(props.order.created_at), "dd-MM-yyyy")}</span>}

                    </div>
                </div>
            </div>

            <div className="row justify-content-around mt-3">
                <div className="col-11 ">
                    <table class="table table-inverse table-hover table-responsive">
                        <thead class="thead-inverse bg-primary border-0 text-white">
                            <tr style={{ fontWeight: 'normal' }}>
                            <th>CONDITIONNEMENT</th>
                                <th>DESIGNATION</th>
                                <th className='text-'>QTE</th>
                                {!props.options.delivery&&<th>PRIX UNIT</th>}
                                {!props.options.delivery&&<th>TOTAL</th>}
                            </tr>
                        </thead>
                        <tbody className="border-0">
                            {props.order.length !== 0 &&
                                order.products.map((p, key) => {
                                    return <tr className="border-0" key={key}>
                                    <td className="border-0">{p.code}</td>
                                        <td className="border-0">{p.name}</td>
                                        <td className="border-0">{p.quantity}</td>
                                        {!props.options.delivery&&<td className="border-0">{priceFormat(p.price)}</td>}
                                        {!props.options.delivery&&<td className="border-0">{priceFormat(p.price * p.quantity)}</td>}                                                                          
                                    </tr>
                                })
                            }
                            {!props.options.delivery&&<tr className='fw-bold border-0'>
                                <td colSpan={3} className="border-0"> </td>
                                <td className='bg-light border-0'>MONTANT HT</td>
                                <td className='bg-light border-0'>{priceFormat(props.order.price)}</td>
                            </tr>}
                            {Number(props.options.tva)>0&&!props.options.delivery&&<tr className='fw-bold border-0'>
                                <td colSpan={3} className="border-0"></td>

                                <td className='bg-light border-0'>TVA {props.options.tva}%</td>
                                <td className='bg-light border-0'>{priceFormat(props.order.price * props.options.tva / 100)}</td>
                            </tr>}
                            {Number(props.options.ir)>0&&!props.options.delivery&&<tr className='fw-bold border-0'>
                                <td colSpan={3} className="border-0"></td>

                                <td className='bg-light border-0'>IR {props.options.ir}%</td>
                                <td className='bg-light border-0'>{priceFormat(props.order.price * props.options.ir / 100)}</td>
                            </tr>}
                            {!props.options.delivery&&<tr className='fw-bold border-0'>
                                <td colSpan={3} className="border-0"></td>

                                <td className='bg-light border-0'>TOTAL TTC</td>
                                <td className='bg-light border-0'>{priceFormat(props.order.price + props.order.price * props.options.tva / 100 - props.order.price * props.options.ir / 100)}</td>
                            </tr>}

                        </tbody>
                    </table>

                </div>
            </div>
            {(props.options.proforma || props.options.invoice) && <div className="row m-4">
                <div style={{ textAlign: "left",marginLeft:"1.8em" }} className="col-12 fs-6 text-left">
                Arrêté la présente facture à la somme de:  
                <span style={{ }} className="fw-bold fs-6 text-decoration-underline text-uppercase">
                  {props.options.price}
                </span>
                </div>
            </div>}
            <div className="row m-4">
                {!props.options.delivery &&<div className="col-6 fw-bold fs-6 text-uppercase">{(!props.options.proforma&&!props.options.invoice) && props.order.customer.firstname}</div>}

                {props.options.delivery &&<div className="col-6 fw-bold fs-5 text-decoration-underline mt-5">Réception</div>}
                <div className="col-6 fw-bold fs-5 text-decoration-underline">La direction</div>
            </div>
        </div>
    )
}
