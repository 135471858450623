import React, { useState } from 'react'
import { Message } from './Message'
import './Modal.scss'

export const Modal = (props) => {

    return (
        <div class={"Modal "+props.className} role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{props.title}</h5>
                        <button onClick={(e) => props.onDismiss(e)} type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            {props.children}
                        </div>
                    </div>
                    <div class="modal-footer">
                        {false&&<button onClick={(e) => props.onDismiss(e)} type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>}
                        <button onClick={(e) => props.onSave(e)} type="button" class="btn btn-primary w-50">{props.saveTitle}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
