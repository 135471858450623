import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addMessage } from '../../../../actions/message.action'
import { addStockCommand, getStockCommand } from '../../../../actions/stockcommand.action'
import { getStock } from '../../../../actions/stock.action'

import { Modal } from '../../../utils/Modal'

export const NewStockCommand = (props) => {
  const [error, setError] = useState()
  const productData = useSelector((state) => state.productReducer)
  const [q, setQ] = useState(null)
  const token = window.localStorage.getItem("token")
  const [quantities, setQuantities] = useState(() => {
    const output = []
    productData.map((val, index) => {
      output.push(null)
    })
    return output
  })
  const [prices, setPrices] = useState(() => {
    const output = []
    productData.map((val, index) => {
      output.push(null)
    })
    return output
  })
  const dispatch = useDispatch()
  const handleSave = () => {
    let data = []
    productData.map((product, key) => {
      quantities[key] !== null && data.push({
        product_id: product.id,
        quantity: quantities[key],
        price: prices[key],
      })
    })
    data.map((sd) => {
      dispatch(addStockCommand(token, sd,
        (message) => {
          dispatch(addMessage(message, "success")); props.onDismiss();
        },
        (message) => {
          //dispatch(addMessage(message, "danger"))
          setError(message)
        }

      ))
    })
    dispatch(getStockCommand(token))
    dispatch(getStock(token))


  }
  const handleChange = (type, id, val) => {
    // console.log(id+","+val)
    if (type === "quantities") {
      let inputValues = quantities.slice()
      inputValues = inputValues.map((v, key) => {
        if (id === "input-" + key) {
          return val
        } else {
          return v
        }
      })
      setQuantities(inputValues)
    } else {
      let inputValues = prices.slice()
      inputValues = inputValues.map((v, key) => {
        if (id === "input-" + key) {
          return val
        } else {
          return v
        }
      })
      setPrices(inputValues)
    }

    //console.log(inputValues)
  }
  return (
    <Modal title={props.title} onSave={() => { handleSave() }} onDismiss={(e) => { props.onDismiss() }} saveTitle={<><i className='bi bi-save'></i> AJOUTER</>}>
      {error && <div className="alert alert-danger">
        {error}
      </div>}
      <div className="row">
        <div className="col col-9 m-2">
          <input value={q} onChange={(e) => setQ(e.target.value)} placeholder='rechercher un produit' type="text" className="form-control mr-sm-2" />
        </div>
      </div>
      <div className="row m-2" styles={{ overflowY: "scroll", maxHeight: "60vh" }}>
        <table class="table table-responsive" style={{border:"none"}}>
          <thead class="thead-inverse">
            <tr>
              <th>Image</th>
              <th>Désignation</th>
              <th>Quantité</th>
              <th>Prix</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>

            {productData.length !== 0 &&
              productData.map((product, key) => {

                const output =
                  <tr>
                    <td style={{ border: "none" }}>
                      <img src={process.env.REACT_APP_IMAGE_BASE_PATH + product.picture} class="img-fluid" style={{ maxHeight: "30px" }} alt="" />
                    </td>
                    <td style={{ border: "none" }}>{product.name}</td>
                    <td style={{ border: "none" }}><input placeholder='quantité' value={quantities[key]} onChange={(e) => { handleChange("quantities", "input-" + key, e.target.value) }} type="text" className="form-control" />
                    </td><input placeholder='prix' value={prices[key]} onChange={(e) => { handleChange("prices", "input-" + key, e.target.value) }} type="text" className="form-control" />

                    <td style={{ border: "none" }}>{quantities[key] * prices[key] > 0 ? quantities[key] * prices[key] + " FCFA" : null}
                    </td>
                  </tr>


                const pName = product.name;
                if (q !== null && q.length > 0) {
                  if (pName.toLowerCase().includes(q.toLowerCase())) {
                    return output
                  }
                } else {
                  return output
                }

              })
            }
          </tbody>
        </table>
        {false && productData.length !== 0 &&
          productData.map((product, key) => {
            const output =
              <div className="row m-1 gap-1">
                <div className="col-2">
                  <img src={process.env.REACT_APP_IMAGE_BASE_PATH + product.picture} class="img-fluid" style={{ maxHeight: "30px" }} alt="" />
                </div>
                <div className="col-2">
                  {product.name}
                </div>

                <div className="col-3">
                  <input placeholder='quantité' value={quantities[key]} onChange={(e) => { handleChange("quantities", "input-" + key, e.target.value) }} type="text" className="form-control" />
                </div>

                <div className="col-3">
                  <input placeholder='prix' value={prices[key]} onChange={(e) => { handleChange("prices", "input-" + key, e.target.value) }} type="text" className="form-control" />
                </div>
                <div className='col-1 mt-3'>
                  {quantities[key] * prices[key] > 0 ? quantities[key] * prices[key] + " FCFA" : null}
                </div>
              </div>

            const pName = product.name;
            if (q !== null && q.length > 0) {
              if (pName.toLowerCase().includes(q.toLowerCase())) {
                return output
              }
            } else {
              return output
            }

          })
        }
      </div>

    </Modal>
  )
}
