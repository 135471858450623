import React from 'react'
import { SignIn } from '../components/Account/SignIn'

export const Login = () => {
  return (
    <div>
        <SignIn/>
    </div>
  )
}
